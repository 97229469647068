export const scheduleData = {
  "talks": {
    "0": {
      "attending_days": [
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 0,
      "title": "A General Lotto Game over Networked Targets",
      "abstract": "Ensuring the security of networked systems is a significant problem, considering the susceptibility of modern infrastructures and technologies to adversarial interference. A central component of this problem is how defensive resources should be allocated to mitigate the severity of potential attacks on the system. In this paper, we consider this in the context of a General Lotto game, where a defender and attacker deploys resources on the nodes of a network, and the objective is to secure as many links as possible. The defender secures a link only if it out-competes the attacker on both of its associated nodes. For bipartite networks, we completely characterize equilibrium payoffs and strategies for both the defender and attacker. On arbitrary network structures, we show the equilibrium payoff from bipartite networks serves as a lower bound on the defender's max-min value.",
      "topic_comment": "Game Theory",
      "scheduling_comment": "Wednesday afternoon, Thursday, and Friday",
      "author": "Adel Aghajan",
      "authors_comment": "Adel Aghajan, Keith Paarporn, Jason R. Marden"
    },
    "1": {
      "attending_days": [
        "2022-05-23"
      ],
      "id": 1,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Dakshi Agrawal",
      "authors_comment": ""
    },
    "2": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 2,
      "title": "Annihilation of Spurious Minima in Two-layer ReLU Networks",
      "abstract": "We study the optimization problem associated with fitting two-layer ReLU neural networks with\nrespect to the squared loss where labels are generated by a target network. Use is made of the\nrich symmetry structure to develop a novel set of tools for studying the mechanism by which over-parameterization annihilates spurious minima. Sharp analytic estimates are obtained for the loss and the Hessian spectrum at different minima, and it is proved that adding neurons can turn symmetric spurious minima into saddles; minima of lesser symmetry require more neurons. This analytic approach uses techniques, new to the field, from representation theory, symmetry breaking and algebraic geometry, and rigorously confirms the effectiveness of over-parameterization in making the associated loss landscape accessible to gradient-based methods.",
      "topic_comment": "Optimization, deep learning, ReLU, nonconvex, symmetry breaking",
      "scheduling_comment": "I might not be able to make to the beginning of the conference. If you can schedule my talk later in the week I would appreciate.",
      "author": "Yossi Arjevani",
      "authors_comment": "Yossi Arjevani, Michael Field"
    },
    "3": {
      "attending_days": [
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 3,
      "title": "Verifiable planning in multichain MDPs",
      "abstract": "The planning domain entails the synthesis of decision-making policies which satisfy formal specifications in the form of some well-defined logic. While many such logics have been proposed with varying degrees of expressiveness and capacity to capture desirable agent behavior, their value is limited when deriving decision-making policies which satisfy certain types of asymptotic behavior in general system models. In this talk, we explore the steady-state planning problem that consists of deriving a decision-making policy for an agent such that constraints on its steady-state behavior are satisfied. A linear programming solution for general multichain MDPs is proposed and we prove that optimal solutions to the proposed programs yield stationary policies with rigorous guarantees of behavior.",
      "topic_comment": "Policy synthesis, Markov Decision Processes, Steady-state planning in AI",
      "scheduling_comment": "Thursday and Friday",
      "author": "George Atia",
      "authors_comment": "George Atia, Andre Beckus, Ismail Alkhouri, Alvaro Velasquez"
    },
    "4": {
      "attending_days": [
        "2022-05-27"
      ],
      "id": 4,
      "title": "LightSecAgg: a Lightweight and Versatile Design for Secure Aggregation in Federated Learning",
      "abstract": "Secure model aggregation is a key component of federated learning (FL) that aims at protecting the privacy of each user's individual model while allowing for their global aggregation. It can be applied to any aggregation-based FL approach for training a global or personalized model. Model aggregation needs to also be resilient against likely user dropouts in FL systems, making its design substantially more complex. State-of-the-art secure aggregation protocols rely on secret sharing of the random-seeds used for mask generations at the users to enable the reconstruction and cancellation of those belonging to the dropped users. The complexity of such approaches, however, grows substantially with the number of dropped users. We propose a new approach, named LightSecAgg, to overcome this bottleneck by changing the design from \"random-seed reconstruction of the dropped users\" to \"one-shot aggregate-mask reconstruction of the active users via mask encoding/decoding\". We show that LightSecAgg achieves the same privacy and dropout-resiliency guarantees as the state-of-the-art protocols while significantly reducing the overhead for resiliency against dropped users. We also demonstrate that, unlike existing schemes, LightSecAgg can be applied to secure aggregation in the asynchronous FL setting. Furthermore, we provide a modular system design and optimized on-device parallelization for scalable implementation, by enabling computational overlapping between model training and on-device encoding, as well as improving the speed of concurrent receiving and sending of chunked masks. We evaluate LightSecAgg via extensive experiments for training diverse models on various datasets in a realistic FL system with large number of users and demonstrate that LightSecAgg significantly reduces the total training time. \n\nFull paper, which will appear in MLSYS'22, is available online at https://arxiv.org/abs/2109.14236.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Salman Avestimehr",
      "authors_comment": "Jinhyun So, Chaoyang He, Chien-Sheng Yang, Songze Li, Qian Yu, Ramy E. Ali, Basak Guler, Salman Avestimehr"
    },
    "5": {
      "attending_days": [
        "2022-05-23"
      ],
      "id": 5,
      "title": "Generative Adversarial Networks for Next-Generation Wireless Networks",
      "abstract": "We employ a machine learning approach known as Generative Adversarial Networks (GANs) for spectrum sharing in next-generation wireless networks. GANs have been very successful for anomaly detection in image processing. We alter\nperformance criteria used in next-generation wireless networks from image processing applications to wireless and employ such networks for spectrum sensing. Our initial results show the advantages of this approach.",
      "topic_comment": "Machine learning applications for wireless communications",
      "scheduling_comment": "Monday, Tuesday",
      "author": "Ender Ayanoglu",
      "authors_comment": "Ender Ayanoglu, Kemal Davaslioglu, Yalin Sagduyu"
    },
    "6": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 6,
      "title": "Mirror Descent Maximizes Generalized Margin and Can Be Implemented Efficiently",
      "abstract": "Driven by the empirical success and wide use of deep neural networks, understanding the generalization performance of overparameterized models has become an increasingly popular question. To this end, there has been substantial effort to characterize the implicit bias of the optimization algorithms used, such as gradient descent (GD), and the structural properties of their preferred solutions. This paper answers an open question in this literature: For the classification setting, what solution does mirror descent (MD) converge to? Specifically, motivated by its efficient implementation, we consider the family of mirror descent algorithms with  potential function chosen as the $p$-th power of the $\\ell_p$-norm, which is an important generalization of GD. We call this algorithm  $p$-GD. For this family, we characterize the solutions it obtains and show that it converges in direction to a \\emph{generalized maximum-margin} solution with respect to the $\\ell_p$-norm for linearly separable classification. While the MD update rule is in general expensive to compute and perhaps not suitable for deep learning,  $p$-GD is fully parallelizable in the same manner as SGD and can be used to train deep neural networks with virtually \\emph{no additional computational overhead}. Using comprehensive experiments with both linear and deep neural network models, we demonstrate that $p$-GD can noticeably affect the structure and the generalization performance of the learned models.",
      "topic_comment": "deep learning, optimization, overparameterization, overfitting, interpolation, regularization",
      "scheduling_comment": "",
      "author": "Navid Azizan",
      "authors_comment": "Haoyuan Sun, Kwangjun Ahn, Christos Thrampoulidis, and Navid Azizan"
    },
    "7": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 7,
      "title": "Communication in the presence of byzantine users: authentication, adversary identification, and reliable communication",
      "abstract": "We consider the problem of communication over multiple access channels, potentially in the presence of byzantine users, i.e., internal adversaries. We consider three progressively difficult criterion for decoding -- detecting the presence of any adversary, identifying the adversarial user(s), and decoding the message from non-adversarial users regardless of the adversarial interference. For each of these settings, we characterize the set of channels where communication is feasible, and find inner and outer bounds on the corresponding capacity regions that match for the first and third settings.",
      "topic_comment": "physical layer security",
      "scheduling_comment": "I would like to present in the graduation day session, if possible.",
      "author": "Mayank Bakshi",
      "authors_comment": "Mayank Bakshi, Neha Sangwan, Vinod Prabhakaran, Bikash Kumar Dey"
    },
    "8": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 8,
      "title": "Loss Regularization for Covariant Data Augmentation",
      "abstract": "While deep learning through empirical risk minimization (ERM) has succeeded at achieving human-level performance at a variety of complex tasks, ERM generalizes poorly to distribution shift. This is partly explained by overfitting to spurious features such as background in images or named entities in natural language. Synthetic data augmentation followed by empirical risk minimization (DA-ERM) is a simple and widely used solution to remedy this problem. In addition, consistency regularization could be applied to further promote model performance to be consistent on the augmented sample and the original one. In this paper, we propose data augmented invariant regularization (DAIR), a simple form of consistency regularization that is applied directly on the loss function rather than intermediate features, making it widely applicable regardless of network architecture or problem setup. We apply DAIR to multiple real-world learning problems, namely robust regression, visual question answering, robust deep neural network training, and neural task-oriented dialog modeling. Our experiments show that DAIR consistently outperforms ERM and DA-ERM with little marginal cost and sets new state-of-the-art results in several benchmarks.",
      "topic_comment": "Robust Machine Learning",
      "scheduling_comment": "As part of the sessions on Robust Machine Learning organized by Yao Qin, Ahmad Beirami, Alex Beutel",
      "author": "Ahmad Beirami",
      "authors_comment": "Tianjian Huang, Shaunak Halbe, Chinnadhurai Sankar, Pooyan Amini, Satwik Kottur, Alborz Geramifard, Meisam Razaviyayn, Ahmad Beirami"
    },
    "9": {
      "attending_days": [
        "2022-05-23",
        "2022-05-25"
      ],
      "id": 9,
      "title": "Dynamic interrogation of stochastic transcriptome trajectories using disease associated genes reveals distinct origins of neurological and psychiatric disorders",
      "abstract": "The advent of open access to genomic data offers new opportunities to revisit old\nclinical debates while approaching them from a different angle. We examine anew\nthe question of whether psychiatric and neurological disorders are different from each\nother by assessing the pool of genes associated with disorders that are understood\nas psychiatric or as neurological. We do so in the context of transcriptome data\ntracked as human embryonic stem cells differentiate and become neurons. Building\nupon probabilistic layers of increasing complexity, we describe the dynamics and\nstochastic trajectories of the full transcriptome and the embedded genes associated\nwith psychiatric and/or neurological disorders. From marginal distributions of a gene’s\nexpression across hundreds of cells, to joint interactions taken globally to determine\ndegree of pairwise dependency, to networks derived from probabilistic graphs along\nmaximal spanning trees, we have discovered two fundamentally different classes of\ngenes underlying these disorders and differentiating them. One class of genes boasts\nhigher variability in expression and lower dependencies (High Expression Variability-\nHEV genes); the other has lower variability and higher dependencies (Low Expression\nVariability-LEV genes). They give rise to different network architectures and different\ntransitional states. HEV genes have large hubs and a fragile topology, whereas LEV\ngenes show more distributed code during the maturation toward neuronal state. LEV\ngenes boost differentiation between psychiatric and neurological disorders also at the\nlevel of tissue across the brain, spinal cord, and glands. These genes, with their low\nvariability and asynchronous ON/OFF states that have been treated as gross data and\nexcluded from traditional analyses, are helping us settle this old argument at more than\none level of inquiry.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Theodoros Bermperidis",
      "authors_comment": "Theodoros Bermperidis, Simon Schafer, Fred H. Gage, Terrence Sejnowski and\nElizabeth B. Torres"
    },
    "10": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24"
      ],
      "id": 10,
      "title": "Observational learning with externalities",
      "abstract": "Observational learning models seek to understand how distributed agents learn from observing the actions of others. In the basic model, agents seek to choose between two alternatives, where the underlying value of each alternative is the same for each agent. Agents do not know this value but only observe a noisy signal of the value and make their decision based this signal and observations of other agents’ actions. Here, instead we consider a stylized scenario in which the choices faced by an agent exhibit a negative externality so that the value of a choice may decrease depending on the history of other agents selecting that choice. We study the learning behavior of Bayesian agents with such an externality and show that this can lead to very different outcomes compared to models without such an externality.",
      "topic_comment": "",
      "scheduling_comment": "Can present on Monday or Tuesday",
      "author": "Randall Berry",
      "authors_comment": "Pawan Poojary and Randall Berry"
    },
    "11": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 11,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Alex Beutel",
      "authors_comment": ""
    },
    "12": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 12,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Supratik Bhattacharjee",
      "authors_comment": ""
    },
    "13": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25"
      ],
      "id": 13,
      "title": "Recovering Data Permutations from Noisy Observations",
      "abstract": "In this talk, we consider a noisy data structure recovery problem. The goal is to investigate the following question: Given a noisy observation of a data vector, what was the true ordering of the input data vector? The focus is on scenarios where data is generated according to an arbitrary exchangeable distribution, and the noise is additive Gaussian with an arbitrary covariance matrix. This problem is posed within an M-ary hypothesis testing framework. First, we characterize the linear regime, in which the decoder has a polynomial complexity, and it declares the data permutation (i.e., ordering of the data vector) by simply computing a permutation-independent linear function of the noisy observations. In particular, we show that the noise covariance matrix must have a very flat spectrum with at most three distinct eigenvalues to induce the linear regime. Second, we focus on the case of isotropic noise, and we characterize the rates of convergence of the probability of error in the high and low noise regimes. Finally, we discuss a few relaxed versions of the problem, which range from the approximate permutation recovery (i.e., recovery up to a fixed distortion) to the partial permutation recovery (i.e., recovery of a portion of the data).",
      "topic_comment": "Permutation recovery, M-ary hypothesis testing, linear estimation.",
      "scheduling_comment": "I would be available to present only on Monday (May 23rd) and Tuesday (May 24th).",
      "author": "Martina Cardone",
      "authors_comment": "Martina Cardone, Minoh Jeong, and Alex Dytso"
    },
    "14": {
      "attending_days": [
        "2022-05-26"
      ],
      "id": 14,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Ryan Cassidy",
      "authors_comment": ""
    },
    "15": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 15,
      "title": "Orthonormal Sketches for Secure Coded Regression",
      "abstract": "In this work, we propose a method for speeding up linear regression distributively, while ensuring security. We leverage randomized sketching techniques, and improve straggler resilience in asynchronous systems. Specifically, we apply a random orthonormal matrix and then subsample in blocks, to simultaneously secure the information and reduce the dimension of the regression problem. In our setup, the transformation corresponds to an encoded encryption in an approximate gradient coding scheme, and the subsampling corresponds to the responses of the non-straggling workers; in a centralized coded computing network. We focus on the special case of the Subsampled Randomized Hadamard Transform, which we generalize to block sampling; and discuss how it can be used to secure the data.",
      "topic_comment": "Coded Computing, Compression Algorithms",
      "scheduling_comment": "",
      "author": "Neophytos Charalambides",
      "authors_comment": "Neophytos Charalambides, Hessam Mahdavifar, Mert Pilanci, Alfred Hero"
    },
    "16": {
      "attending_days": [
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 16,
      "title": "Understanding Robustness in Non-Parametric Methods",
      "abstract": "Motivated by machine learning in safety-critical applications, robustness to adversarial examples has seen a great deal of recent interest. In this talk, we will look at robustness in the context of classical non-parametric methods, such as nearest neighbors, decision trees and kernel classifiers. We will look at a new large sample limit, when robustness is desired within balls of a certain radius, and investigate what kinds of classifiers converge to this limit. If time permits, we will also look at a new limit that naturally balances robustness and accuracy from a max-margin perspective.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Kamalika Chaudhuri",
      "authors_comment": "Robi Bhattacharjee"
    },
    "17": {
      "attending_days": [],
      "id": 17,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Jiawen Chen",
      "authors_comment": ""
    },
    "18": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24"
      ],
      "id": 18,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Daniel Costello",
      "authors_comment": ""
    },
    "19": {
      "attending_days": [
        "2022-05-23",
        "2022-05-25"
      ],
      "id": 19,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Sanjoy Dasgupta",
      "authors_comment": ""
    },
    "20": {
      "attending_days": [
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 20,
      "title": "Beyond Random Reshuffling: Example Order Selection in Machine Learning",
      "abstract": "Training example order in SGD has long been known to affect convergence rate. Recent results show that accelerated rates are possible in a variety of cases for permutation-based sample orders, in which each example from the training set is used once before any example is reused. This talk will cover a line of work in my lab on sample-ordering schemes. We will discuss the limits of the classic random-reshuffling scheme and explore how it can be improved, understanding when we can simultaneously achieve the best theoretical rates as well as empirical performance and applicability.",
      "topic_comment": "machine learning",
      "scheduling_comment": "Thursday/Friday would be better",
      "author": "Christopher De Sa",
      "authors_comment": ""
    },
    "21": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24"
      ],
      "id": 21,
      "title": "Tutorial on Quantum Error Correction and Recent Developments in Quantum LDPC Codes",
      "abstract": "to be supplied later",
      "topic_comment": "Quantum LDPC Codes, both construction and decoding, and using machine learning for decoding will be discussed.\nWe would like Dr. Narayanan Rengaswamy that recently has been hired by University of Arizona to give this tutorial talk",
      "scheduling_comment": "Prefer either Monday or Tuesday but if not possible I will ask Dr. Narayanan Rengaswamy if he is staying longer.\nI am staying Sunday, Monday and Tuesday only.",
      "author": "Dariush Divsalar",
      "authors_comment": "N. Rengaswamy, N. Raveendran, B. Vasic, D. Divsalar, M. Cheng and S. Dolinar"
    },
    "22": {
      "attending_days": [
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 22,
      "title": "Site-specific path loss prediction at mm-wave for Manhattan street canyons",
      "abstract": "Large bandwidth at mm-wave is crucial for 5G and beyond but the high path loss (PL) requires highly accurate PL prediction for network planning and optimization. We propose an ML-based urban canyon PL prediction model based on extensive 28 GHz measurements from Manhattan where street clutters are modeled via a LiDAR point cloud dataset and buildings by a mesh-grid building dataset. We extract expert knowledge-driven street clutter features from the point cloud and aggressively compress 3D-building information using convolutional-autoencoder. Using a new street-by-street training and testing procedure to improve generalizability, the proposed model using both clutter and building features achieves a prediction error (RMSE) of 4.8 ± 1.1 dB compared to 6.5 ± 2.0 dB for slope-intercept prediction, where the standard deviation indicates street-by-street variation.",
      "topic_comment": "wireless communication, or application of machine learning",
      "scheduling_comment": "Thursday any time is best, then Wednesday before students talk (need to talk with students afterwards). Friday is a bit challenging for me. Monday/Tuesday I'm not there.",
      "author": "Jinfeng DU",
      "authors_comment": "Ankit Gupta; Jinfeng Du; Dmitry Chizhik; Reinaldo A. Valenzuela; Mathini Sellathurai"
    },
    "23": {
      "attending_days": [
        "2022-05-25"
      ],
      "id": 23,
      "title": "Enabling Virtual MIMO with smart surfaces",
      "abstract": "In the last decade, bandwidth expansion and MIMO spatial multiplexing have promised to increase data throughput by orders of magnitude. However, we are yet to enjoy such improvement in real-world environments, as they lack rich scattering and preclude effective MIMO spatial multiplexing. In this talk, I will talk about my work \"ScatterMIMO\", which uses a smart surface to increase the scattering in the environment, to provide MIMO spatial multiplexing gain.",
      "topic_comment": "Wireless communication, Mimo",
      "scheduling_comment": "Wednesday early morning.",
      "author": "Manideep Dunna",
      "authors_comment": ""
    },
    "24": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 24,
      "title": "Multi-User Linearly Separable Computation",
      "abstract": "In this work, we investigate the problem of multi-user linearly separable computation, where N servers help compute the desired functions of K users while each desired function can be written as a linear combination of up to L (generally non-linear) sub-functions. Each server computes some of the subtasks, and communicates a linear combination of its computed outputs (files) in a single-shot to some of the users, where each user then linearly combines its received data in order to recover its desired function. We explore the range of the optimal computation cost via establishing a novel relationship with coding theory.",
      "topic_comment": "Distributed computing",
      "scheduling_comment": "I am available Tuesday, Wednesday, and the first half of Thursday",
      "author": "Petros Elia",
      "authors_comment": "Ali Khalesi and Petros Elia"
    },
    "25": {
      "attending_days": [
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 25,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Mostafa El-Khamy",
      "authors_comment": ""
    },
    "26": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25"
      ],
      "id": 26,
      "title": "Classification of balanced sequences",
      "abstract": "Balanced sequences were always subject to classification, hierarchy, and construction algorithms.\nIn this talk we review some of the previous hierarchies and algorithms and present a hierarchy\nand construction algorithms which generalize previous ones.",
      "topic_comment": "sequences",
      "scheduling_comment": "Please schedule on Monday or Tuesday in  a session for the memory of Alexander Vardy",
      "author": "Tuvi Etzion",
      "authors_comment": "Sagi Marcovich and Eitan  Yaakobi"
    },
    "27": {
      "attending_days": [
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 27,
      "title": "Correcting Multiple Short Duplication and Substitution Errors",
      "abstract": "Data storage in DNA, despite its advantages over traditional methods, suffers from a diverse set of errors including deletions, insertions, duplications, and substitutions. This talk focuses on error-correcting codes for simultaneously correcting short (tandem) duplications and at most $p$ substitutions, where a short duplication generates a copy of a substring with length $\\leq 3$ and inserts the copy following the original substring. \nCompared to the state-of-the-art codes for duplications only, the proposed codes correct up to $p$ substitutions (in addition to duplications) at the additional cost of roughly $8p(\\log_q n) (1+o(1))$ symbols of redundancy, thus achieving the same asymptotic rate, where $q\\ge 4$ is the alphabet size. The time complexities of both the encoding and decoding processes are polynomial when $p$ is a constant with respect to $n$.",
      "topic_comment": "coding theory, data storage in DNA",
      "scheduling_comment": "Please schedule my talk for Thursday or Friday, as I have already purchased my ticket. Preference on Thursday, if at all possible. Thanks.",
      "author": "Farzad Farnoud",
      "authors_comment": "Yuanyuan Tang, Shuche Wang, Ryan Gabrys, Farzad Farnoud"
    },
    "28": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24"
      ],
      "id": 28,
      "title": "Solving Multi-Arm Bandit Using a Few Bits of Communication",
      "abstract": "The multi-armed bandit (MAB) problem is an active learning framework that aims to select the best among a set of actions by sequentially observing rewards. Recently, it has become popular for a number of applications over wireless networks, where communication constraints can form a bottleneck. Existing works usually fail to address this issue and can become infeasible in certain applications. In this work we address the communication problem by optimizing the communication of rewards collected by distributed agents. By providing nearly matching upper and lower bounds, we tightly characterize the number of bits needed per reward for the learner to accurately learn without suffering additional regret. In particular, we establish a generic reward quantization algorithm, QuBan, that can be applied on top of any (no-regret) MAB algorithm to form a new communication-efficient counter-part, that requires only a few (as low as 3) bits to be sent per iteration while preserving the same regret bound.",
      "topic_comment": "Active learning, Multi-Arm Bandits, Communication Constraints",
      "scheduling_comment": "I can only present on Monday or Tuesday morning",
      "author": "Christina Fragouli",
      "authors_comment": "Osama A. Hanna, Lin F. Yang, Christina Fragouli"
    },
    "29": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 29,
      "title": "A Lego-brick approach to lossy source coding",
      "abstract": "In this talk, I will describe a general approach to coding for network communication. Inspired by coding schemes developed in network information theory, the goal is to identify the minimum primitive properties that point-to-point channel codes should satisfy so that they can be used as building blocks in the design of coding schemes for various multiterminal coding problems. As an example, I will present a coding scheme for the lossy source coding problem starting from a pair of point-to-point channel codes that are designed for symmetric channels. Assuming that the constituent channel codes satisfy some properties on the rate, the error probability and the input-output distribution of the decoding functions, bounds on the average distortion of the coding scheme hold irrespective of other properties of the codes. Moreover, the proposed coding scheme achieves the rate-distortion bound, provided that the constituent codes are rate-optimal. The developed approach would allow one to leverage commercial off-the-shelf codes for point-to-point symmetric channels in the practical implementation of codes over networks.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Nadim Ghaddar",
      "authors_comment": ""
    },
    "30": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 30,
      "title": "Randomized scheduling of packets with hard deadline constraints over wireless fading channels",
      "abstract": "Despite the rich literature on scheduling algorithms for wireless networks, algorithms that can provide deadline\nguarantees on packet delivery for general traffic and interference models are very limited. In this paper, we study the problem of scheduling real-time traffic under a conflict-graph interference model with unreliable links due to channel fading. Packets that are not successfully delivered within their deadlines are of no value. We consider traffic (packet arrival and deadline) and fading (link reliability) processes that evolve as an unknown finite-state Markov chain. The performance metric is efficiency ratio which is the fraction of packets of each link which are delivered within their deadlines compared to that under the optimal (unknown) policy. We first show a conversion result that shows classical non-real-time scheduling algorithms can be ported to the real-time setting and yield a constant efficiency ratio, in particular, Max-Weight Scheduling (MWS) yields an efficiency ratio of 1/2. We then propose randomized algorithms that achieve efficiency ratios strictly higher than 1/2, by carefully randomizing over the maximal schedules. We further propose low-complexity and myopic distributed randomized algorithms, and characterize their efficiency ratio.",
      "topic_comment": "Scheduling, Randomized Algorithms, Resource Allocation, Wireless Networks",
      "scheduling_comment": "",
      "author": "Javad Ghaderi",
      "authors_comment": "Christos Tsanikidis, Javad Ghaderi"
    },
    "31": {
      "attending_days": [
        "2022-05-25"
      ],
      "id": 31,
      "title": "Private Deep Learning Inference on a ReLU Budget",
      "abstract": "Deploying modern deep learning in real world applications comes with several challenges. Due to high data and compute demand, several parties who have access to these resources are required to collaborate during training and deployment for a successful uptake. This collaboration however, raises immediate security concerns relating to the privacy of parties' assets. In this talk, I will focus on privacy-preserving inference computation, and show that naive adoption of cryptographic protocols into existing models results in unexpected overheads. The community is largely unprepared to address these overheads, as the source of slowdown in private inference stems from the ReLU operator whereas optimizations for plaintext inference focus on optimizing FLOPs. I demonstrate the need for re-thinking both deep learning and cryptography, and argue for their co-design. With this insight, I provide several optimization avenues that significantly reduce private inference runtime and storage costs.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Zahra Ghodsi",
      "authors_comment": ""
    },
    "32": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 32,
      "title": "Decentralized Competing Bandits in Non-Stationary Matching Markets",
      "abstract": "Understanding complex dynamics of two-sided online matching markets, where the demand-side agents compete to match with the supply-side (arms), has recently received substantial interest. To that end, in this paper, we introduce the framework of decentralized two-sided matching market under non stationary (dynamic) environments. We adhere to the serial dictatorship setting, where the demand-side agents have unknown and different preferences over the supply-side (arms), but the arms have fixed and known preference over the agents. We propose and analyze a decentralized and asynchronous learning algorithm, namely Decentralized Non-stationary Competing Bandits (\\texttt{DNCB}), where the agents play  (restrictive) successive elimination type learning algorithms to learn their preference over the arms. The complexity in understanding such a system stems from the fact that the competing bandits choose their actions in an asynchronous fashion, and the lower ranked agents only get to learn from a set of arms, not \\emph{dominated} by the higher ranked agents, which leads to \\emph{forced exploration}. With carefully defined complexity parameters, we characterize this \\emph{forced exploration} and obtain sub-linear (logarithmic) regret of \\texttt{DNCB}. Furthermore, we validate our theoretical findings via experiments.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Avishek Ghosh",
      "authors_comment": ""
    },
    "33": {
      "attending_days": [
        "2022-05-26"
      ],
      "id": 33,
      "title": "Information theory based opportunities for advancing neural prostheses",
      "abstract": "Over the last two decades neural prostheses that aim to restore lost motor function have moved quickly from concept to laboratory development and clinical demonstration. In parallel, advances in neural interfacing technologies poised to broaden clinical application of these prostheses are actively in development in both academic and industry settings. Although physical sensing methods are advancing rapidly and facilitate higher fidelity brain state estimation, power efficient sampling and telemetry are critical bottlenecks to future performance gains. I will provide a brief introduction to this problem space and describe a recently developed compressed sensing strategy.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Vikash Gilja",
      "authors_comment": ""
    },
    "34": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25"
      ],
      "id": 34,
      "title": "Sliced mutual information: a scalable measure of statistical dependence",
      "abstract": "Mutual information (MI) is a fundamental measure of statistical dependence, with a myriad of applications to information theory, statistics, and machine learning. While it possesses many virtuous properties, the estimation of high-dimensional MI suffers from the curse of dimensionality, whereby the number of data points needed to obtain reliable estimates grows exponentially with dimension. To overcome this bottleneck, this talk will introduce sliced MI (SMI) as a surrogate measure of dependence. SMI is defined as an average of MI terms between one-dimensional random projections. We will first show that SMI preserves many of the structural properties of classic MI, from identification of independence to (sliced) entropy decomposition, and variational forms. Next, we will focus on formal guarantees for empirical estimation, demonstrating that SMI between high-dimensional random vectors can be estimated at a rate corresponding to classic MI between scalar variables. We will also discuss key differences between SMI and MI, and present simple numerical experiments to independence testing and feature extraction, highlighting potential gains SMI offers over classic MI for high-dimensional inference and learning.",
      "topic_comment": "",
      "scheduling_comment": "I am arriving on Monday night and leaving early Thursday morning. Therefore, I can present only on Tuesday or Wednesday, with a preference for anything but Tuesday morning.",
      "author": "Ziv Goldfeld",
      "authors_comment": "Ziv Goldfeld, Kristjan Greenewald, and Galen Reeves"
    },
    "35": {
      "attending_days": [
        "2022-05-26"
      ],
      "id": 35,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Govind Gopal",
      "authors_comment": ""
    },
    "36": {
      "attending_days": [
        "2022-05-27"
      ],
      "id": 36,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Quanquan Gu",
      "authors_comment": ""
    },
    "37": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24"
      ],
      "id": 37,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "T H",
      "authors_comment": ""
    },
    "38": {
      "attending_days": [
        "2022-05-26"
      ],
      "id": 38,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Iman Hajirasouliha",
      "authors_comment": ""
    },
    "39": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 39,
      "title": "Density ratio estimation based on nearest neighbor matching",
      "abstract": "Nearest neighbor (NN) matching as a tool to align data sampled from different groups is both conceptually natural and practically well-used. In a landmark paper, Abadie and Imbens (2006) provided the first large-sample analysis of NN matching under, however, a crucial assumption that the number of NNs, M, is fixed. This manuscript reveals something new out of their study and shows that, once allowing M to diverge with the sample size, an intrinsic statistic in their analysis actually constitutes a one-step, minimax optimal, density ratio estimator of near-linear time complexity, the first method to achieve all three desirable properties. In the talk, we will also discuss some subsequent ideas.",
      "topic_comment": "density ratio and f-divergence estimation",
      "scheduling_comment": "Monday and Tuesday are preferred.",
      "author": "Fang Han",
      "authors_comment": "Zhexiao Lin, Peng Ding, and Fang Han"
    },
    "40": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 40,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Zaid Harchaoui",
      "authors_comment": ""
    },
    "41": {
      "attending_days": [
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 41,
      "title": "Is Importance Weighting Incompatible with Overparametrization?",
      "abstract": "Importance weighting is a classic technique to handle distribution shifts. However, prior work has presented strong empirical and theoretical evidence demonstrating that importance weights can have little to no effect on overparameterized neural networks. Is importance weighting truly incompatible with the training of overparameterized neural networks? We answer this in the negative. We show that importance weighting fails not because of the overparameterization, but instead, as a result of using exponentially-tailed losses like the logistic or cross-entropy loss. As a remedy, we show that polynomially-tailed losses restore the effects of importance reweighting in correcting distribution shifts in overparameterized models. Finally, we demonstrate the practical value of our analysis with neural network experiments on a subpopulation shift and a label shift dataset. Our polynomially-tailed loss consistently increases the test accuracy by 2-3%.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Tatsunori Hashimoto",
      "authors_comment": "Alex Wang, Niladri Chatterji, Saminul Haque"
    },
    "42": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25"
      ],
      "id": 42,
      "title": "The curse of overparametrization in adversarial training",
      "abstract": "Successful deep learning models often involve training neural network architectures that\ncontain more parameters than the number of training samples. Such overparametrized models\nhave been extensively studied in recent years, and the virtues of overparametrization have been\nestablished from both the statistical perspective, via the double-descent phenomenon, and the\ncomputational perspective via the structural properties of the optimization landscape.\nDespite the remarkable success of deep learning architectures in the overparametrized regime,\nit is also well known that these models are highly vulnerable to small adversarial perturbations\nin their inputs. Even when adversarially trained, their performance on perturbed inputs\n(robust generalization) is considerably worse than their best attainable performance on benign\ninputs (standard generalization). It is thus imperative to understand how overparametrization\nfundamentally affects robustness.\nIn this talk, we will provide a precise characterization of the role of overparametrization on\nrobustness by focusing on random features regression models (two-layer neural networks with\nrandom first layer weights). We consider a regime where the sample size, the input dimension\nand the number of parameters grow in proportion to each other, and derive an asymptotically\nexact formula for the robust generalization error when the model is adversarially trained. Our\ndeveloped theory reveals the nontrivial effect of overparametrization on robustness and indicates\nthat for adversarially trained random features models, high overparametrization can hurt robust\ngeneralization.",
      "topic_comment": "Robust ML",
      "scheduling_comment": "I'm attending from Monday to Wednesday",
      "author": "Hamed Hassani",
      "authors_comment": "Hamed Hassani and Javanmard"
    },
    "43": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 43,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Ashwin Hebbar",
      "authors_comment": ""
    },
    "44": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25"
      ],
      "id": 44,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "S Ashwin Hebbar",
      "authors_comment": ""
    },
    "45": {
      "attending_days": [
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 45,
      "title": "Private Linear Computation: New Results and Open Problems",
      "abstract": "In this talk, we present new achievability schemes for Private Linear Computation (PLC) under two different privacy guarantees, and show the optimality of the proposed schemes using new converse proof techniques. In addition, we talk about the role of side information in PLC, and discuss several related open problems.",
      "topic_comment": "Private Information Retrieval, Private Function Computation, Information-Theoretic Privacy",
      "scheduling_comment": "I would appreciate it if you could please schedule my talk for Thursday (morning or afternoon) or Friday morning. I have to catch a flight on Friday afternoon.",
      "author": "Anoosheh Heidarzadeh",
      "authors_comment": "Anoosheh Heidarzadeh, Nahid Esmati, Alex Sprintson"
    },
    "46": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25"
      ],
      "id": 46,
      "title": "Joint Information and Mechanism Design for Queues with Heterogeneous Users",
      "abstract": "In this work, we combine mechanism design and information design to study a queuing system with heterogeneous users. We consider a queue with unobservable backlog by the incoming users who have payoff relevant private types. There is a planner who observes the queue backlog and designs a mechanism and an admission policy for the users. A user, upon arrival, decides to either be admitted to the queue by the planner or join the queue or not on her own. If she decides to be admitted by the planner, she has to send a message, that is supposed to be her type, to the planner. The planner then creates an admission signal for that specific type of user. Information about the queue backlog is conveyed through the admission signal and the users have to pay a tax in exchange for the information they receive. In this setting, the planner is both an information designer (designs the admission policy) and a mechanism designer (designs the tax function) and he has commitments to both his admission policy and the tax function. We formulate an optimization problem that characterizes the solution of the joint design problem. We characterize a tax function that ensures dominant strategy incentive compatibility and we provide structural results for the optimal admission policy. Our results are supported by numerical analysis.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Nasimeh Heydaribeni",
      "authors_comment": "Nasimeh Heydaribeni and Achilleas Anastasopoulos"
    },
    "47": {
      "attending_days": [
        "2022-05-24"
      ],
      "id": 47,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Tawaana Homavazir",
      "authors_comment": ""
    },
    "48": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 48,
      "title": "NeurWIN: Neural Whittle Index Network For Restless Bandits Via Deep RL",
      "abstract": "Whittle index policy is a powerful tool to obtain asymptotically optimal solutions for the notoriously intractable problem of restless bandits. However, finding the Whittle indices remains a difficult problem for many practical restless bandits with convoluted transition kernels. This paper proposes NeurWIN, a neural Whittle index network that seeks to learn the Whittle indices for any restless bandits by leveraging mathematical properties of the Whittle indices. We show that a neural network that produces the Whittle index is also one that produces the optimal control for a set of Markov decision problems. This property motivates using deep reinforcement learning for the training of NeurWIN. We demonstrate the utility of NeurWIN by evaluating its performance for three recently studied restless bandit problems. Our experiment results show that the performance of NeurWIN is significantly better than other RL algorithms.",
      "topic_comment": "Reinforcement learning, restless bandit, Whittle index",
      "scheduling_comment": "Tuesday and Thursday",
      "author": "I-Hong Hou",
      "authors_comment": "Khaled Nakhleh, Santosh Ganji, Ping-Chun Hsieh, I-Hong Hou, Srinivas Shakkottai"
    },
    "49": {
      "attending_days": [],
      "id": 49,
      "title": "Predicted COVID-19 pandemic ending for each continent with Steed covariance",
      "abstract": "Spectrum-based Mass-Charge modeling has received significant interest in recent years and is increasingly used in bioinformatic analysis. To explain the statistical phenomenon with positive and negative fluctuations of amino acid charges in spike protein genomic sequences from SARS-CoV-2 variant omicron and other coronaviruses, we propose a calculation-based Mass-Charge modeling. Different from previous semi-covariance co-efficiency method, this study proposes a normalized derivation with the exact Excel and Matlab tool algorithm. By examining the relative peak and dip of the average with spike protein genomic sequences of coronaviruses based on the hydrophobic mass to isoelectric charges of the amino acids, the proposed algorithm provides more clear insights leading to the detail revealing of the underline evolving trends of bioinformatic entropy of the related infectivity and the virulence. The hydrophobic interaction is an entropic effect originating from the disruption of the dynamic hydrogen bonds between molecules of water by the nonpolar molecules forming a clathrate-like structure around. The entropic effect is from the second law of thermodynamics. The spike proteins from coronaviruses isolated from the murine for animal and from Omicron, Delta, IHU, OC43, 229E, HKU1, NL63 that cause human infections of each region were taken as the representative examples in this study.",
      "topic_comment": "Statistics, Entropy, Prediction, ReLU, Regression",
      "scheduling_comment": "Monday afternoon is the best, otherwise Monday morning, Friday afternoon, Friday morning ......",
      "author": "Jun Huang",
      "authors_comment": "Jun Huang, Michelle Chen, Tommy Zhou, Tong Xu, Moran Huang, Wandong Zhang"
    },
    "50": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 50,
      "title": "Monte Carlo Methods for Randomized Likelihood Decoding",
      "abstract": "Cuff’s lemma states that in estimating a source X from an observation Y, making a random guess X′ from the posterior p(x|y) can go wrong at most twice as often as the optimal answer. Based on this fundamental, yet rather arcane, result, we studied a randomized decoder that generates the message estimate according to the posterior distribution. With the goal of practical implementations of such a randomized decoder, several Monte Carlo Markov chain (MCMC) techniques, such as Gibbs sampling and the Metropolis algorithm, are adapted to the problem of efficient sampling from the posterior. The performance and the complexity of these randomized decoders are analyzed through numerical experiments.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Jiun-Ting Huang",
      "authors_comment": ""
    },
    "51": {
      "attending_days": [
        "2022-05-25"
      ],
      "id": 51,
      "title": "Limitation of Neural Collapse on Generalization",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Like Hui",
      "authors_comment": ""
    },
    "52": {
      "attending_days": [
        "2022-05-26"
      ],
      "id": 52,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Basileal Imana",
      "authors_comment": ""
    },
    "53": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 53,
      "title": "An Active Learning Based Data Discovery Framework Using the Submodular Information Measures",
      "abstract": "Active Learning is an old yet very powerful framework for adaptively sampling data points from an unlabeled set to reduce the labeling cost. Indeed, active learning has shown great promise in several domains (image and text classification, object detection, speech recognition, etc.), and shown promise by improving the labeling efficiencies by factors of 3x to 5x compared to random sampling!\n \nIn this work, we provide a unified framework for performing active learning for realistic scenarios using the recently developed submodular information measures. Real-world datasets have several imperfections including redundancy, rare classes and slices, imbalance, biases, out-of-distribution instances, and outliers. We propose a four-phase paradigm for active learning which involves, a) exploration, b) discovery, c) targeting, and d) filtering. The exploration phase involves selecting samples that are diverse, uncertain, and representative and several existing active learning approaches fall under this paradigm. However, as we will show in this talk, this does not address the realistic scenarios listed above. Through “discovery”, we will discover unknown classes and slices (not seen in the labeled set due to them being rare – also called unknown unknowns). Next, through “targeting”, we will target the discovered rare classes and slices to improve the model performance on these, and finally through “filtering”, we will guide the active learning to filter out outliers and out-of-distribution instances. We will demonstrate the utility of this framework in several domains and scenarios including image classification, medical imaging, self-driving cars, etc. In each case, we demonstrate 5% to 10% accuracy improvements and up to 10x labeling efficiencies on rare classes and slices.",
      "topic_comment": "Combinatorial Optimization, Active Learning, Submodular Information Measures",
      "scheduling_comment": "Preferred on Tue, Wed, or Thu. I would not prefer early morning slots and would like something after 10 am if possible.",
      "author": "Rishabh Iyer",
      "authors_comment": "Rishabh Iyer and Suraj Kothawade"
    },
    "54": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 54,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Tanvi Jadhav",
      "authors_comment": ""
    },
    "55": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 55,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Ayush Jain",
      "authors_comment": ""
    },
    "56": {
      "attending_days": [
        "2022-05-25"
      ],
      "id": 56,
      "title": "Towards Reliable and High Throughput Millimeter-wave Links with multi-beamforming",
      "abstract": "Millimeter-wave communication with high throughput and high reliability is poised to be a gamechanger for V2X and VR applications. However, mmWave links are notorious for low reliability since they suffer from frequent outages due to blockage and user mobility. We build mmReliable, a reliable mmWave system that implements\nmulti-beamforming and user tracking to handle environmental vulnerabilities. It creates constructive multi-beam patterns and optimizes their angle, phase, and amplitude to maximize the signal strength at the receiver. Multi-beam links are reliable since they are resilient to occasional blockages of few constituent beams compared to a single-beam system. We implement mmReliable on a 28 GHz testbed with 400 MHz bandwidth, and a 64 element phased\narray supporting 5G NR waveforms. Rigorous indoor and outdoor experiments demonstrate that mmReliable achieves close to 100% reliability providing 2.3x improvement in the throughput-reliability product than single-beam systems.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Ish Jain",
      "authors_comment": ""
    },
    "57": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 57,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Tara Javidi",
      "authors_comment": ""
    },
    "58": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24"
      ],
      "id": 58,
      "title": "Circuits Resilient to Short-Circuit Errors",
      "abstract": "Given a Boolean circuit C, we wish to convert it to a circuit C' that computes the same function as C even if some of its gates suffer from adversarial short circuit errors, i.e., their output is replaced by the value of one of their inputs. Can we design such a resilient circuit C' whose size is roughly comparable to that of C? Prior work gave a positive answer for the special case where C is a formula.  We study the general case and show that any Boolean circuit C of size s can be converted to a new circuit C' of quasi-polynomial size s^{O(log s)} that computes the same function as C even if\na 1/51 fraction of the gates on any root-to-leaf path in C' are short circuited. Moreover, if the original circuit C is a formula, the resilient circuit C' is of near-linear size s^{1+e}. The construction of our resilient circuits utilizes the connection between circuits and DAG-like communication protocols, originally introduced in the context of proof complexity.",
      "topic_comment": "Communication complexity, error resilience.",
      "scheduling_comment": "Monday and Tuesday only.",
      "author": "Yael Tauman Kalai",
      "authors_comment": "Klim Efremenko, Bernhard Haeupler, Yael Tauman Kalai, Pritish Kamath, Gillat Kol, Nickolas Resch, Raghuvansh Saxena"
    },
    "59": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24"
      ],
      "id": 59,
      "title": "A theory of unsupervised machine translation with application to understanding whale communication",
      "abstract": "Under what theoretical conditions can one learn to translate between two languages based only on independent samples from each language, without any translations? We present an information-theoretic approach to this problem, viewing the problem of translation as one of compressing samples from one language using a model of the other. Recently, massive deep neural networks such as GPT-3 and PaLM have demonstrated impressive capabilities of learning languages from samples alone. One interesting potential application of this approach would be to translating communication from intelligent non-human animals, such as whales, into a natural human language, such as English.",
      "topic_comment": "language modeling, translation, deep learning, compression",
      "scheduling_comment": "Monday and Tuesday only",
      "author": "Adam Kalai",
      "authors_comment": "Shafi Goldwasser, Adam Kalai, Orr Paradise"
    },
    "60": {
      "attending_days": [
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 60,
      "title": "Pricing for Efficient Equilibria in Internet Traffic Exchange",
      "abstract": "In this talk, we present our recent results on the pricing of port capacities at internet exchange points with the goal of attaining efficient traffic equilibria. We analyze both proportional and constant per-unit pricing, and show that for both pricing policies, there exists prices that can simultaneously attain a low social cost and high revenue. We further observe that the corresponding price-of-anarchy (PoA) values can be expressed in terms of certain sublinearity measures of the inverse demand curves of the players. The theoretical analysis are substantiated by numerical results that demonstrate that the theoretical bounds correctly capture the performance trends against the variation in price.",
      "topic_comment": "traffic pricing; network games; price-of-anarchy analysis.",
      "scheduling_comment": "Anytime Thursday or Friday works. \nIf Wednesday, prefer avoiding early morning session (as I will be arriving very late on Tue).",
      "author": "Koushik Kar",
      "authors_comment": "Md. Ibrahim Alam, Elliot Anshelevich and Koushik Kar"
    },
    "61": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24"
      ],
      "id": 61,
      "title": "Sequence Reconstruction Problem for Deletion Channels: A Complete Asymptotic Solution",
      "abstract": "Transmit a codeword $\\vx$, that belongs to an $(\\ell-1)$-deletion-correcting code of length $n$, over a $t$-deletion channel for some $1\\le \\ell\\le t<n$. Levenshtein, in 2001, proposed the problem of determining $N(n,\\ell,t)+1$, the minimum number of distinct channel outputs required to uniquely reconstruct $\\vx$. Prior to this work, $N(n,\\ell,t)$ is known only when $\\ell\\in\\{1,2\\}$. Here, we provide an asymptotically exact solution for all values of $\\ell$ and $t$. Specifically, we show that $N(n,\\ell,t)=\\binom{2\\ell}{\\ell}/(t-\\ell)! n^{t-\\ell} - O(n^{t-\\ell-1})$ and in the special instance where $\\ell=t$, we show that $N(n,\\ell,\\ell)=\\binom{2\\ell}{\\ell}$. We also provide a conjecture on the exact value of $N(n,\\ell,t)$ for all values of $n$, $\\ell$, and $t$.",
      "topic_comment": "deletion correcting codes, DNA-based data storage",
      "scheduling_comment": "Mon and Tue",
      "author": "Han Mao Kiah",
      "authors_comment": "Van Long Phuoc Pham, Keshav Goyal"
    },
    "62": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 62,
      "title": "Advancing information theory and coding via deep learning",
      "abstract": "",
      "topic_comment": "deep learning for channel coding; especially for network scenarios (such as interference channels, broadcast channels); might cover distributed source coding as well",
      "scheduling_comment": "I'll be there on Mon & Tue almost surely. Wed to Fri is less likely, so I'd prefer MonTue slots.  If that's hard, I'll try to make Thu/Fri. Thank you! -->>> [Update on 5/19] My plan has changed -- I'll be at the conference on Mon, Tue, Wed. Thank you.",
      "author": "Hyeji Kim",
      "authors_comment": ""
    },
    "63": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24"
      ],
      "id": 63,
      "title": "RELDEC: Reinforcement Learning-Based Decoding of Moderate Length LDPC Codes",
      "abstract": "In this talk we present RELDEC, a novel approach for sequential decoding of moderate length low-density parity-check (LDPC) codes. The main idea behind RELDEC is that an optimized decoding policy is subsequently obtained via reinforcement learning based on a Markov decision process. The proposed decoding scheme outperforms standard flooding and random sequential decoding for a variety of LDPC codes of moderate lengths up to 500 bits, including codes designed for 5G new radio.",
      "topic_comment": "Machine learning for communications",
      "scheduling_comment": "Monday or Tuesday",
      "author": "Joerg Kliewer",
      "authors_comment": "Salman Habib, Allison Beemer, Joerg Kliewer"
    },
    "64": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 64,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Zhifeng Kong",
      "authors_comment": ""
    },
    "65": {
      "attending_days": [
        "2022-05-27"
      ],
      "id": 65,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Victoria Kostina",
      "authors_comment": ""
    },
    "66": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25"
      ],
      "id": 66,
      "title": "Wringing out the multiple-access channel: a second-order converse bound",
      "abstract": "A converse bound is presented for the multiple-access channel under the average probability of error constraint. This bound shows that for most channels of interest, the second-order coding rate—that is, the difference\nbetween the best achievable rates and the asymptotic capacity region as a function of the blocklength n with fixed probability of error—is $O(n^{-1/2})$ bits per channel use. The proof uses a variation of Ahlswede's wringing technique, strengthened by means of a new measure of dependence between two random variables called \"wringing dependence\".",
      "topic_comment": "Multiple-access channel, second-order",
      "scheduling_comment": "Monday, Tuesday, or no later than 10:30am on Wednesday",
      "author": "Oliver Kosut",
      "authors_comment": "Oliver Kosut"
    },
    "67": {
      "attending_days": [
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 67,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Farinaz Koushanfar",
      "authors_comment": ""
    },
    "68": {
      "attending_days": [
        "2022-05-24",
        "2022-05-26"
      ],
      "id": 68,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Madison Lee",
      "authors_comment": ""
    },
    "69": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 69,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Xinlin Li",
      "authors_comment": ""
    },
    "70": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25"
      ],
      "id": 70,
      "title": "Quantum Learning With and Without Quantum Memory",
      "abstract": "The goal of this talk is to survey recent results in quantum learning theory on learning with and without quantum memory. We demonstrate that for a number of natural and well-considered problems in quantum distribution learning, algorithms that do not have access to persistent quantum memory provably require more samples. Notably, these results are information theoretic and are not conditional on any complexity theoretic assumptions. Remarkably, in several simple settings, algorithms without quantum memory require exponentially more samples than those with quantum memory. No prior background in quantum learning is required or expected for this talk.",
      "topic_comment": "quantum learning",
      "scheduling_comment": "",
      "author": "Jerry Li",
      "authors_comment": "Sebastien Bubeck, Sitan Chen, Jordan Cotler, Hsin-Yuan Huang, Jerry Li"
    },
    "71": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 71,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Bill Lin",
      "authors_comment": ""
    },
    "72": {
      "attending_days": [
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 72,
      "title": "Cyber Insurance and the Ransomware Twist",
      "abstract": "Compared to traditional types of insurable risk, cyber risk is distinct in its dependency on a much larger set of actors' decisions, not just the one purchasing the insurance. This prevalent risk dependency and externality in decision making gives rise to new ways of designing an insurance policy.  Ransomware risk carries further complications in additional types of externality that require careful consideration.  I will present a few game-theoretic models to analyze the underwriting of cyber risk in general and ransomware risk more specifically.  In doing so I will draw a number of parallels between ransomware attacks and the centuries-old crime, kidnapping for ransom, and highlight lessons we can learn in conceptualizing an effective framework around the design and governance of ransomware insurance.",
      "topic_comment": "Game theory; incentive mechanism; cybersecurity",
      "scheduling_comment": "Thursday and Friday, with a strong preference for Thursday -- I was not able to book Friday night at the hotel and will need to depart sometime on Friday.",
      "author": "Mingyan Liu",
      "authors_comment": "Tongxin Yin, Mingyan Liu"
    },
    "73": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 73,
      "title": "Incentivized Bandit Learning with Self-Reinforcing User Preferences",
      "abstract": "In this paper, we investigate a new multi-armed bandit (MAB) online learning model that considers real-world phenomena in many recommender systems: (i) the learning agent cannot pull the arms by itself and thus has to offer payments to users to incentivize arm-pulling indirectly; and (ii) if users with specific arm preferences are well\nrewarded, they induce a “self-reinforcing” effect in the sense that they will attract more users of\nsimilar arm preferences. Besides addressing the tradeoff of exploration and exploitation, another key feature of this new MAB model is to balance reward and incentivizing payment. The goal of the agent is to minimize the accumulative regret over a fixed time horizon T with a low total payment. Our contributions in this paper are twofold: (i) We propose a new MAB model with random arm selection that considers the relationship of users’ self-reinforcing preferences and incentives; and (ii) We leverage the properties of a multi-color Polya urn with nonlinear feedback ´\nmodels to propose two MAB policies termed “AtLeast-n Explore-Then-Commit” and “UCB-List.” We prove that both policies achieve O(log T) expected regret with O(log T) expected paymentover a time horizon T. We conduct numerical simulations to demonstrate and verify the performances of these two policies and study their robustness under various settings.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Jia Liu",
      "authors_comment": "Tianchen Zhou, Jia Liu, Chaosheng Dong, and Jingyuan Deng"
    },
    "74": {
      "attending_days": [
        "2022-05-27"
      ],
      "id": 74,
      "title": "Certifiably robust collaborative inference via feature purification",
      "abstract": "Collaborative inference leverages diverse features provided by different agents\n(e.g., sensors) for more accurate inference. In this work, we consider each agent\nsends its embedded features instead of the raw data to the Fusion Center (FC)\nfor joint prediction. Such distributed data collection process and invisibility of\nthe raw data pose severe security challenges on the inference. We consider the\ninference-time attack where a small fraction of agents are compromised. The\ncompromised agent either does not send embedded features to the FC, or sends\narbitrarily wrong embedded features. To address this, we propose a certifiably\nrobust COllaborative inference framework via feature PURification (CoPur), by\nleveraging the block-sparse nature of adversarial perturbations on the feature vector,\nas well as exploring the underlying redundancy across the embedded features (by\nassuming the overall features lie on an underlying lower dimensional manifold).\nWe theoretically show that the proposed feature purification method can recover\nthe underlying true feature vector exactly or approximately, despite adversarial\ncorruptions and/or incomplete observations.",
      "topic_comment": "Adversarial ML, Vertical Federated Learning, Sparsity",
      "scheduling_comment": "I can only make Friday, preferably Friday afternoon",
      "author": "Jing Liu",
      "authors_comment": "Jing Liu, Chulin Xie, Sanmi Koyejo, Bo Li"
    },
    "75": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 75,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Yuhan Liu",
      "authors_comment": ""
    },
    "76": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 76,
      "title": "------------------- (no talk this time) -------------------",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "No talk this time, but looking forward to the workshop.",
      "author": "Hans-Andrea Loeliger",
      "authors_comment": ""
    },
    "77": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 77,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Andrey Lokhov",
      "authors_comment": ""
    },
    "78": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 78,
      "title": "Low-rate channel coding: constructions and bounds",
      "abstract": "In this talk, we present some of our recent results on low-rate channel coding. In terms of code construction, schemes based on concatenating the state-of-the-art codes at moderate rates with repetition codes have emerged as solutions in practice. We discuss novel mechanisms for concatenating outer polar codes with inner repetition codes which we refer to as polar coded repetition. In terms of properties of binary codes in the low rate regime, a classical problem is to study bounds on the size of the largest code given a certain normalized minimum distance that is very close to half. We will discuss novel lower and upper bounds  in this regime, where the size of the best code scale polynomially with block length.",
      "topic_comment": "Coding theory",
      "scheduling_comment": "Monday, Tuesday, Thursday",
      "author": "Hessam Mahdavifar",
      "authors_comment": "Hessam Mahdavifar"
    },
    "79": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 79,
      "title": "Reinforcement Learning with Almost Sure Constraints",
      "abstract": "Motivated by the need to provide machine learning tools with reliability guarantees for safety-critical applications, in this talk, we address the problem of finding feasible policies for Constrained Markov Decision Processes under probability one constraints. We argue that stationary policies are not sufficient for solving this problem. A sufficiently rich class of policies can be found by endowing the agent with a scalar state--so-called budget--that tracks how close the agent is to violating the constraint. We show that the minimum budget required to act safely (given state-action pairs) can be obtained as the smallest fixed point of a Bellman-like operator, for which we analyze its convergence properties. We also show how to learn this quantity when the Markov decision process's kernel is unknown and further provide sample-complexity bounds. The knowledge of this minimal budget ensures feasibility and aids in the search for optimal policies by shrinking down the region of the state space the agent must explore. Simulations illustrate the different nature of probability one constraints against the typically used expectation constraints.",
      "topic_comment": "Reinforcement Learning.",
      "scheduling_comment": "Tuesday, Thursday (morning), and Friday.",
      "author": "Enrique Mallada",
      "authors_comment": "Agustin Castellano, Hancheng Min, Juan Bazerque, Enrique Mallada"
    },
    "80": {
      "attending_days": [
        "2022-05-23",
        "2022-05-26"
      ],
      "id": 80,
      "title": "Location Leakage in Federated Signal Maps",
      "abstract": "We consider the problem of predicting cellular network performance (signal maps) from measurements collected by several mobile devices. We formulate the problem within the online federated learning framework: (i) federated learning (FL) enables users to collaboratively train a model, while keeping their training data on their devices; (ii) measurements are collected as users move around over time and are used for local training in an online fashion. We consider an honest-but-curious server, who observes the updates from target users participating in FL and infers their location using a deep leakage from gradients (DLG) type of attack, originally developed to reconstruct training data of DNN image classifiers. We make the key observation that a DLG attack, applied to our setting, infers the average location of a batch of local data, and can thus be used to reconstruct the target users' trajectory at a coarse granularity. We show that a moderate level of privacy protection is already offered by the averaging of gradients, which is inherent to Federated Averaging. Furthermore, we propose an algorithm that devices can apply locally to curate the batches used for local updates, so as to effectively protect their location privacy without hurting utility. Finally, we show that the effect of multiple users participating in FL depends on the similarity of their trajectories. To the best of our knowledge, this is the first study of DLG attacks in the setting of FL from crowdsourced spatio-temporal data.",
      "topic_comment": "Privacy Attacks in Machine Learning.",
      "scheduling_comment": "If you have to schedule me on Thursday please AVOID THE 12-2:30 SLOT when I am teaching. Friday is open.",
      "author": "Athina Markopoulou",
      "authors_comment": "Evita Bakopoulou, Jiang Zhang, Justin Ley, Konstantinos Psounis, Athina Markopoulou"
    },
    "81": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25"
      ],
      "id": 81,
      "title": "On learning mixture of linear regressions in the non-realizable setting",
      "abstract": "While mixture of linear regressions (MLR) is a well-studied topic, prior works usually do not  analyze such models for prediction error.  In fact, prediction and loss are not well-defined in the context of mixtures. In this presentation, first we show that MLR can be used for prediction where instead of predicting a label, the model predicts a list of values (also known as list-decoding). The list size is equal to the number of components in the mixture, and the loss function is defined to be minimum among the losses resulted by all the component models. We show that with this definition, a solution of the empirical risk minimization (ERM) achieves small probability of prediction error. This begs for an algorithm to minimize the empirical risk for MLR, which is known to be computationally hard.  Prior algorithmic works in MLR focus on the realizable setting, i.e., recovery of parameters when data is probabilistically generated by a mixed linear (noisy) model. Here we show that a version of the popular alternating minimization (AM) algorithm finds the best fit lines in a dataset even when a realizable model is not assumed, under some regularity conditions on the dataset and the initial points, and thereby provides a solution for the ERM. We further provide an algorithm that runs in polynomial time in the number of datapoints, and recovers a good approximation of the best fit lines. The two algorithms are experimentally compared.",
      "topic_comment": "Mixtures, Algorithms",
      "scheduling_comment": "Please schedule this Mon-Wed. I will be away Thursday onward",
      "author": "Arya Mazumdar",
      "authors_comment": "Avishek Ghosh, Arya Mazumdar, Soumyabrata Pal, Rajat Sen"
    },
    "82": {
      "attending_days": [
        "2022-05-23",
        "2022-05-25",
        "2022-05-27"
      ],
      "id": 82,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Mike McCloud",
      "authors_comment": ""
    },
    "83": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 83,
      "title": "Optimal correlators and waveforms for mismatched detection",
      "abstract": "We consider the classical Neymann-Pearson hypothesis testing problem of signal detection, where under the null hypothesis, the received signal is white Gaussian noise, and under the alternative hypothesis, the received\nsignal includes also an additional non-Gaussian random signal, which in turn can be viewed as a deterministic waveform plus zero-mean, non-Gaussian noise. However, instead of the classical likelihood ratio test detector, which might be difficult to implement, in general, we impose a (mismatched) correlation detector, which is relatively easy to implement, and we characterize the optimal correlator weights in the sense of the best trade-off\nbetween the false-alarm error exponent and the missed-detection error exponent. Those optimal correlator weights depend (non-linearly, in general) on the underlying deterministic waveform. We then assume that the deterministic waveform may also be free to be optimized (subject to a power constraint), jointly with the correlator, and show that both the optimal waveform and the optimal correlator weights may take on values in a small finite set of\ntypically no more than two to four levels, depending on the distribution of the non-Gaussian noise component. Finally, we outline an extension of the scope to a wider class of detectors that are based on linear combinations of\nthe correlation and the energy of the received signal.",
      "topic_comment": "Signal detection theory",
      "scheduling_comment": "Please avoid Friday.",
      "author": "Neri Merhav",
      "authors_comment": "Neri Merhav"
    },
    "84": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 84,
      "title": "Learning and Adaptation for Millimeter-Wave Beam Tracking and Training: a Dual Timescale Variational Framework",
      "abstract": "Millimeter-wave vehicular networks incur enormous beam-training overhead to enable narrow-beam communications. This work proposes a learning and adaptation framework in which the dynamics of the communication beams are learned and then exploited to design adaptive beam-tracking and training with low overhead: on a long-timescale, a deep recurrent variational autoencoder (DR-VAE) uses noisy beam-training feedback to learn a probabilistic model of beam dynamics and enable predictive beam-tracking; on a short-timescale, an adaptive beam-training procedure is formulated and optimized via point-based value iteration by leveraging probabilistic predictions provided by the DR-VAE. In turn, beam-training feedback is used to refine the DR-VAE in a continuous process of learning and adaptation.",
      "topic_comment": "Millimeter-wave wireless communications\nBeam Alignment\nMachine learning for Wireless comm.",
      "scheduling_comment": "Any day except Friday.",
      "author": "Nicolo Michelusi",
      "authors_comment": "Muddassar Hussain, Nicolo Michelusi"
    },
    "85": {
      "attending_days": [
        "2022-05-26"
      ],
      "id": 85,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Siavash Mirarab",
      "authors_comment": ""
    },
    "86": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 86,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Sidhant Misra",
      "authors_comment": ""
    },
    "87": {
      "attending_days": [
        "2022-05-23",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 87,
      "title": "Mixed On-line/Off-line Q-learning for Directed Network Policy Optimization",
      "abstract": "Wireless communication networks are well-modeled by Markov Decision Processes (MDPs), but induce a large state space which challenges policy optimization. Herein, a novel on-line/off-line Q-learning methodology is proposed based on a new graph representation for wireless networks. These representations based on co-link matrices provide good characterizations of directed graphs. By exploiting structural properties of the wireless network MDP, several structurally related Markov chains, based on the graph representation are created and these multiple chains are sampled to learn multiple policies which are fused. Thus, multiple trajectories can be captured by which to do policy optimization.  The proposed hybrid strategy offers significantly improved convergence rates and performance. The hope is that this approach can be generalized for other directed graphs.",
      "topic_comment": "offline/online learning, graph signal processing, wireless networks, policy optimization",
      "scheduling_comment": "Wednesday or Thursday please",
      "author": "Urbashi Mitra",
      "authors_comment": "Talha Bozkus and Urbashi Mitra"
    },
    "88": {
      "attending_days": [
        "2022-05-23"
      ],
      "id": 88,
      "title": "Distributed Two-Time Scale Gradient Descent over Time-varying Networks",
      "abstract": "We study non-convex distributed optimization problems over time-varying networks, where a set of agents collaboratively solve a separable optimization problem. Practically, exchanging the exact information introduces a massive communication overhead. We introduce and study a two time-scale decentralized algorithm with a broad class of lossy information-sharing methods (including noisy, quantized, and/or compressed information sharing) over time-varying networks. In our method, one time-scale suppresses the (imperfect) incoming information from the neighboring agents, and one time-scale operates on local cost functions' gradients. We show that with a proper choice for the step-size parameters, the algorithm achieves a convergence rate of O(T^{-1/3+\\epsilon}) for non-convex distributed optimization problems over time-varying networks for any \\epsilon>0.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Soheil Mohajer",
      "authors_comment": "Hadi Reisizadeh, Behrouz Touri, Soheil Mohajer"
    },
    "89": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24"
      ],
      "id": 89,
      "title": "Representation Learning with Model-Agnostic Meta-Learning (MAML)",
      "abstract": "Recent empirical evidence has driven conventional wisdom to believe that gradient-based meta-learning (GBML) methods perform well at few-shot learning because they learn an expressive data representation that is shared across tasks. However, the mechanics of GBML have remained largely mysterious from a theoretical perspective. In this talk, we show that GBML methods, such as Model-Agnostic Meta-Learning (MAML), are capable of learning a common representation among a set of given tasks in the well-known multi-task linear representation learning setting. Our analysis illuminates that the driving force causing GBML methods to learn the underlying representation is that they adapt the final layer of their model, which harnesses the underlying task diversity to improve the representation in all directions of interest.",
      "topic_comment": "Meta-Learning, Representation Learning",
      "scheduling_comment": "Monday and Tuesday",
      "author": "Aryan Mokhtari",
      "authors_comment": "Liam Collins, Aryan Mokhtari, Sewoong Oh, Sanjay Shakkottai"
    },
    "90": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 90,
      "title": "Mean-field analysis of piecewise linear solutions for wide ReLU networks",
      "abstract": "Understanding the properties of neural networks trained via stochastic gradient descent (SGD) is at the heart of the theory of deep learning. In this work, we take a mean-field view, and consider a two-layer ReLU network trained via SGD for a univariate regularized regression problem. Our main result is that SGD is biased towards a simple solution: at convergence, the ReLU network implements a piecewise linear map of the inputs, and the number of \"knot\" points - i.e., points where the tangent of the ReLU network estimator changes - between two consecutive training inputs is at most three. In particular, as the number of neurons of the network grows, the SGD dynamics is captured by the solution of a gradient flow and, at convergence, the distribution of the weights approaches the unique minimizer of a related free energy, which has a Gibbs form. Our key technical contribution consists in the analysis of the estimator resulting from this minimizer: we show that its second derivative vanishes everywhere, except at some specific locations which represent the \"knot\" points. We also provide empirical evidence that knots at locations distinct from the data points might occur, as predicted by our theory.",
      "topic_comment": "Theory of deep learning",
      "scheduling_comment": "I plan to come for the whole workshop.",
      "author": "Marco Mondelli",
      "authors_comment": "Alexander Shevchenko, Vyacheslav Kungurtsev, and Marco Mondelli"
    },
    "91": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 91,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Pierre Moulin",
      "authors_comment": ""
    },
    "92": {
      "attending_days": [
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 92,
      "title": "The impacts of labeling and measurement errors on fairness criteria",
      "abstract": "As we increasingly rely on data-driven, artificially intelligent algorithms to aid or automate decision making, we face the challenge of ensuring that these algorithms do not exhibit or amplify our existing social biases. An issue complicating the design of such fair AI is that algorithms are trained on datasets that can themselves be tainted due to the social biases of prior (human or AI) decision makers. We investigate the robustness of a number of existing (demographic) fairness criteria when the algorithm is trained on biased data. We consider two forms of dataset bias: labeling biases and feature measurement errors. We analytically show that some constraints (such as Demographic Parity) can remain robust when facing certain statistical biases, while others (such as Equalized Odds) are in general violated if trained on biased data. We provide numerical experiments based on three real-world datasets (the FICO, Adult, and German credit score datasets) supporting our analytical findings.",
      "topic_comment": "Fairness in AI, AI ethics, data biases",
      "scheduling_comment": "Thursday, Friday morning",
      "author": "Parinaz Naghizadeh",
      "authors_comment": "Yiqiao Liao, Parinaz Naghizadeh"
    },
    "93": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25"
      ],
      "id": 93,
      "title": "Uncertainty-Based Non-Parametric Active Peak Detection",
      "abstract": "Active, non-parametric peak detection is considered. As a use case, active source localization is examined and an uncertainty-based sampling scheme algorithm to effectively localize the peak from a few energy measurements is designed. It is shown that under very mild conditions, the source localization error with $m$ actively chosen energy measurements scales as $O(\\log^2 m/m)$. Numerically, it is shown that in low-sample regimes, the proposed method enjoys superior performance on several types of data and outperforms the state-of-the-art passive source localization approaches and in the low sample regime, can outperform greedy methods as well.",
      "topic_comment": "Active Learning, Signal Processing,",
      "scheduling_comment": "I am currently thinking of attending Tuesday and Wednesday, but I can modify this a bit based on availability.",
      "author": "Praneeth NarayanaMurthy",
      "authors_comment": "Praneeth Narayanamurthy and Urbashi Mitra"
    },
    "94": {
      "attending_days": [
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 94,
      "title": "HashBeam: Enabling Feedback through Downlink Beamforming in Unsourced Random Access",
      "abstract": "Unsourced random access (URA) has emerged as a candidate paradigm for massive machine-type communication (MTC) in next-generation wireless networks. While many excellent uplink schemes have been developed for URA, these schemes do not specify a mechanism for providing feedback regarding whether a user's message was successfully decoded. While this may be acceptable in some MTC scenarios, the lack of feedback is inadequate for applications that demand a high level of reliability. However, the problem of providing feedback to active users is complicated by the fact that the base station does not know the identities of the active users. In this paper, a novel downlink beamforming scheme called HashBeam is presented that enables the base station to provide feedback to the active users within URA, despite not knowing their identities. \nThe key idea of this scheme is that the users' channels and hashes of their messages may be used as proxies for their true identities. The proposed scheme gracefully adapts itself to any number of antennas at the base station and it is shown that the required number of channel uses is linear in the number of users to acknowledge. The idea of using channel gains in conjunction with user hashes as discriminating attributes of active users is novel and expands the design space of URA schemes.",
      "topic_comment": "Wireless communications",
      "scheduling_comment": "I will be attending on Wednesday and Thursday morning only.",
      "author": "Krishna Narayanan",
      "authors_comment": "Jamison Ebert, Krishna R. Narayanan, Jean-Francois Chamberland"
    },
    "95": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 95,
      "title": "An Information-Theoretic View of Mixed-Delay Traffic in 5G and 6G",
      "abstract": "Fifth generation mobile communication systems (5G) have to accommodate both Ultra-Reliable Low-Latency Communication (URLLC) and enhanced Mobile Broadband (eMBB) services. While eMBB applications support high data rates, URLLC services aim at guaranteeing low-latencies and high-reliabilities. eMBB and URLLC services are scheduled on the same frequency band, where the different latency requirements of the communications render the coexistence challenging.\n\nIn this talk, we review, from an information theoretic perspective, coding schemes that simultaneously accommodate URLLC and eMBB transmissions and show that they outperform traditional scheduling approaches. We consider various communication scenarios, including point-to-point channels, broadcast channels, interference networks, cellular models, and cloud radio access networks (C-RANs).",
      "topic_comment": "Joint  channel coding of mixed-delay traffic. More specifically, reviewing coding schemes that simultaneously accommodate URLLC and eMBB transmissions from an information-theoretic point of view.",
      "scheduling_comment": "I am planning to attend from Monday to Friday. If possible, I would prefer to present on Monday, Tuesday or Wednesday.",
      "author": "Homa Nikbakht",
      "authors_comment": "Homa Nikbakht, Michèle Wigger, Malcolm Egan,  Shlomo Shamai (Shitz), Jean-Marie Gorce, and H.Vincent Poor"
    },
    "96": {
      "attending_days": [
        "2022-05-25"
      ],
      "id": 96,
      "title": "PassBeam: Passive Beamformer for mmWave Sensing and Communications",
      "abstract": "Millimeter-wave (mmWave) technologies have seen explosive growth in the past few years with bleeding edge technologies offering unprecedented communication capacity and sensing resolutions. Unfortunately, they also introduce new challenges such as limitations in communication/sensing range, coverage blind spots and severe \ndisruptions due to blockages from environmental obstructions.  In this paper, we propose PassBeam, a fully passive, thin, PCB-fabricated reflective surface which leverages the reflect-array concept to the far-field regime. PassBeam can resteer incidental mmWave signals towards  anomalous directions to divert around obstructions, and reshape the signals into wider/narrower or multi-beam patterns to fill the coverage holes.  We introduce post-fabrication techniques that enable incremental deployment of PassBeam, to adapt to environment/specification changes, enhancing the SNR or broadening the angular coverage of an existing surface. We further design a multi-user synthesis algorithm that enables multiple transmitters to share the same PassBeam surface while avoiding interference. Our experiments with 5G towers, WiGig access points and automotive radars, demonstrate that PassBeam can effectively improve coverage and extend sensing range, without any modification to existing network infrastructure or sensing devices.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "John Nolan",
      "authors_comment": "John Nolan\nXinyu Zhang"
    },
    "97": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24"
      ],
      "id": 97,
      "title": "Channel Estimation for Reconfigurable Intelligent Surfaces -- Challenges and Capacity Implication",
      "abstract": "Reconfigurable intelligent surfaces (RIS) consist of a large collection of small reflectors whose reflection coefficients can be controlled by various means, e.g., reactive elements or switches changing reflector shapes. RIS are not encumbered by the encoding and decoding circuitry of a relay, or the required emitted power, and thus are emerging as a cost-effective method for controlling signals and interference in a wireless network. The rapidly expanding literature on RIS signifies serious potential, but several fundamental challenges have also been identified. One of them is channel estimation, because a passive RIS can neither generate nor directly detect pilots, one must rely on estimating the cascade channel whose dimension quickly gets out of hand. This talk explores the implications on channel estimation and the RIS-induced channel capacity.",
      "topic_comment": "Wireless communications",
      "scheduling_comment": "the talk can be presented on Thursday or Friday",
      "author": "Aria Nosratinia",
      "authors_comment": "Bharath Shamasundar and Aria Nosratinia"
    },
    "98": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25"
      ],
      "id": 98,
      "title": "Pliable Private Information Retrieval",
      "abstract": "We formulate a new variant of the private information retrieval (PIR) problem where the user is pliable, i.e., interested in \\emph{any} message from a desired subset of the available dataset, denoted as pliable private information retrieval (PPIR). We consider the setup where a dataset consisting of $f$ messages is replicated in $n$ noncolluding databases and classified  into $\\Gamma$ classes. For this setup, the user wishes to retrieve \\emph{any} $\\lambda\\geq 1$ messages from \\emph{multiple} desired classes, while revealing no information about the identity of the desired classes to the databases. We term this problem multi-message PPIR (M-PPIR) and introduce the single-message PPIR (PPIR) problem as an elementary special case of M-PPIR. We first derive  converse  bounds on the M-PPIR download rate, followed by achievable schemes. As a result, we show that the PPIR capacity for $f$ messages and  $\\Gamma$ classes matches the PIR capacity with $n$ noncolluding databases and $\\Gamma$ messages. Thus, enabling flexibility, i.e., pliability, where privacy is only guaranteed for classes, but not for messages as in classical PIR, allows to trade-off privacy versus download rate. A similar insight is shown to hold for the general case of M-PPIR.",
      "topic_comment": "Private Information Retrieval, Information-theoretic Privacy",
      "scheduling_comment": "",
      "author": "Sarah Obead",
      "authors_comment": "Sarah A. Obead, Joerg Kliewer"
    },
    "99": {
      "attending_days": [
        "2022-05-25",
        "2022-05-27"
      ],
      "id": 99,
      "title": "Great talk",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Alon Orlitsky",
      "authors_comment": "Claude"
    },
    "100": {
      "attending_days": [
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 100,
      "title": "Federated Nested Optimization",
      "abstract": "Standard federated optimization methods successfully apply to stochastic problems with single-level structure. However, many contemporary ML problems (e.g. adversarial robustness, hyperparameter tuning, actor-critic) fall under nested bilevel programming that subsumes minimax and compositional optimization. In this talk, we introduce FedNest: A federated alternating stochastic gradient method to address general nested problems. FedNest enjoys provable convergence rates in the presence of heterogeneous data. We discuss various design choices and introduce multiple innovations including federated hypergradient computation and variance reduction to address inner-level heterogeneity.",
      "topic_comment": "federated learning, bilevel optimization, minimax optimization",
      "scheduling_comment": "Friday's \"Federated Learning\" session at 1:30pm is the best match. Thursday's Fairness session can work as well (it is relevant at a high level).\nMore broadly, Thursday and Friday are fully free for me",
      "author": "Samet Oymak",
      "authors_comment": "Davoud Ataee Tarzanagh, Mingchen Li, Christos Thrampoulidis, Samet Oymak"
    },
    "101": {
      "attending_days": [
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 101,
      "title": "Keep them guessing: The value of randomized resource assignments in General Lotto games",
      "abstract": "We consider a commander that decides how to assign costly resources to independent sub-colonels, each engaged in competition against opponents. Here, we consider General Lotto games as the underlying model of competition. While optimal deterministic assignments have been characterized in this context, we completely characterize optimal randomized assignments. Randomizing induces informational asymmetries, where the opponents become uncertain about the sub-colonels' actual assigned resources. In order to derive optimal assignments, we provide a complete analysis of the underlying incomplete and asymmetric information General Lotto games induced from randomized assignments. We find that randomized assignments can give up to a four-fold performance improvement over deterministic ones.",
      "topic_comment": "Game theory, Control",
      "scheduling_comment": "Preference towards middle to end of the week.",
      "author": "Keith Paarporn",
      "authors_comment": "Keith Paarporn, Rahul Chandan, Mahnoosh Alizadeh, Jason Marden"
    },
    "102": {
      "attending_days": [
        "2022-05-23",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 102,
      "title": "On Generalizing the Perron-Frobenius Theorem to Time-Varying Networks",
      "abstract": "Perron-Frobenius theorem is a fundamental tool in matrix analysis that has applications to probability theory, complex networks, population dynamics, social learning, internet search engines, and in the studies of numerous engineering, physical, social, and economic phenomena. However, this theorem and many of its extensions can be applied only to a single matrix at a time, thereby limiting their applications in understanding networked dynamical systems to static networks. To extend the applicability of these results to time-varying networks, we generalize two assertions of the Perron-Frobenius theorem to sequences as well as continua of row-stochastic matrices. Besides having important implications for both discrete-time and continuous-time distributed averaging and for non-Bayesian learning, our results have potential applications in related areas such as distributed optimization and estimation, and more generally, in advancing the state-of-the-art understanding of dynamical processes over real-world networks.",
      "topic_comment": "network analysis, networked control systems",
      "scheduling_comment": "Wednesday, Thursday, or Friday (preferred), Monday - Friday (acceptable)",
      "author": "Rohit Parasnis",
      "authors_comment": "Rohit Parasnis, Massimo Franceschetti, Behrouz Touri"
    },
    "103": {
      "attending_days": [
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 103,
      "title": "Adversarially Robust Learning with Tolerance",
      "abstract": "Adversarial examples---imperceptibly small perturbations to the input that lead a classification model to change its label---have been a major topic of concern in the research community. A large body of work, both empirical and theoretical, has come out with the aim of learning models that have very few adversarial examples, if any. However, currently there is a disconnect between theory and practice---algorithms with theoretical guarantees are complicated and not used in practice, and the practical algorithms do not have guarantees.\n\nIn the present work, we introduce a small and natural tweak in the problem statement (tolerance) and show that combining some popular practical tricks leads to a simple algorithm that has theoretical guarantees better than the best known theoretical algorithms.",
      "topic_comment": "Main topic is learning theory. Specifically, a PAC-learning algorithm for adversarially robust learning.",
      "scheduling_comment": "Please can it be scheduled on Thursday or Friday?\nI arrive Wednesday around noon, so would like to avoid Wednesday afternoon in case there are any delays.",
      "author": "Vinayak Pathak",
      "authors_comment": "Hassan Ashtiani\nVinayak Pathak\nRuth Urner"
    },
    "104": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 104,
      "title": "Robust Binary Classification under Sparse Attacks",
      "abstract": "It is well-known that machine learning models are vulnerable to small but cleverly-designed adversarial perturbations that can cause misclassification. While there has been major progress in designing attacks and defenses for various adversarial settings, many fundamental and theoretical problems are yet to be resolved. In this paper, we consider classification in the presence of l0-bounded adversarial perturbations, a.k.a. sparse attacks. Under the assumption that data is distributed according to the Gaussian mixture model, our goal is to characterize the optimal robust classifier and the corresponding robust classification error as well as a variety of trade-offs between robustness, accuracy, and the adversary's budget. To this end, we develop a novel classification algorithm called FilTrun that has two main modules: Filtration and Truncation. The key idea of our method is to first filter out the non-robust coordinates of the input and then apply a carefully-designed truncated inner product for classification. By analyzing the performance of FilTrun, we derive an upper bound on the optimal robust classification error. We also find a lower bound by designing a specific adversarial strategy that enables us to derive the corresponding robust classifier and its achieved error. For the case that the covariance matrix of the Gaussian mixtures is diagonal, we show that as the input's dimension gets large, the upper and lower bounds converge; i.e. we characterize the asymptotically-optimal robust classifier. We further propose a defense method that consists of “truncation\" and “adversarial training\".  We conduct numerical experiments in the domain of computer vision using the MNIST and CIFAR datasets, demonstrating significant improvement for the robust classification error of neural networks.",
      "topic_comment": "Robust Machine Learning",
      "scheduling_comment": "non-Wednesday",
      "author": "Ramtin Pedarsani",
      "authors_comment": "Payam Delgosha, Hamed Hassani, Ramtin Pedarsani"
    },
    "105": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25"
      ],
      "id": 105,
      "title": "Reed-Muller Codes Achieve Capacity on BMS Channels",
      "abstract": "An outline is presented of our recent proof that Reed-Muller (RM) codes achieve capacity on binary memoryless symmetric (BMS) channels with respect to bit-error rate.  This resolves a long-standing open problem that connects information theory and error-correcting codes.  The proof generalizes some elements of the previous proof for the binary erasure channel (BEC) but also derives new tools to avoid steps that do not generalize.  For example, in contrast with the general BEC result, the new proof does not rely on hypercontractivity.  Instead, it combines a nesting property of RM codes with new information inequalities relating the generalized extrinsic information transfer function and the extrinsic minimum mean-squared error.\n\nThis is the first part of a double header and Galen Reeves will present the second part following this talk.",
      "topic_comment": "RM / error-correcting codes (see title)",
      "scheduling_comment": "I will attend M-W and can present on Mon or Tue.  Galen Reeves and I plan to present 1 part each of this work.  Please schedule our talks sequentially with this talk first.",
      "author": "Henry Pfister",
      "authors_comment": "Galen Reeves and Henry D. Pfister"
    },
    "106": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24"
      ],
      "id": 106,
      "title": "Privacy against Pattern Matching Attacks",
      "abstract": "Suppose we are given a large number of sequences on a given alphabet, and an adversary is interested in identifying (de-anonymizing) a specific target sequence based on its patterns. Our goal is to thwart such an adversary by obfuscating the target sequences by applying artificial (but small) distortions to its values. We would like to make no assumptions about the statistical model of such sequences. We relate this problem to a set of combinatorial and graph theoretical questions based on which we are able to obtain provable guarantees. This problem could be relevant to important privacy applications: from fingerprinting webpages visited by users through anonymous communication systems to linking communicating parties on messaging applications to inferring activities of users of IoT devices.",
      "topic_comment": "Privacy",
      "scheduling_comment": "Monday (strongly preferred) or Tuesday",
      "author": "Hossein Pishro-Nik",
      "authors_comment": "Saeede Enayati, Bo Guan, Dennis Goeckel, Amir Houmansadr, Hossein Pishro-Nik"
    },
    "107": {
      "attending_days": [
        "2022-05-24",
        "2022-05-26"
      ],
      "id": 107,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Rohan Ramchandra Pote",
      "authors_comment": ""
    },
    "108": {
      "attending_days": [
        "2022-05-23"
      ],
      "id": 108,
      "title": "Debiasing generative models using counterfactual methods",
      "abstract": "Variational autoencoders (VAEs) have garnered growing interest not just for their generative properties but also for the ability to dis-entangle low-dimensional embedded latent variable space. However, few existing generative models take causality into account. We thus propose a new VAE-based framework named De-Bias CausalVAE, which includes a partially trainable Causal Layer in which a part of a causal model can be learned without significantly impacting identification. By learning the causal relationships between semantic variables, we can analyze biases, intervene on the generative model, and even block biases from being learned altogether. Furthermore, using the causal structure, we can generate samples well outside the domain of the original training data using counterfactual reasoning and interventions. Thus, datasets with known biases can still be used to train the causal VAEs and learn the semantic relationships, but we can produce de-biased datasets on the generative side. \nOur proposed method combines a causal latent space VAE model with the latest in disentanglement research to demonstrate finer control over the causal layer and the ability to prevent learning biases. We explore how better disentanglement impacts causal learning and causal intervention quality. We also compare our model against similar research to demonstrate the need for explicit de-biasing beyond interventions. Our experiments show that our model can generate images with high fidelity to the causal framework and accommodate explicit de-biasing to ignore undesired relationships in the causal data compared to the baseline.",
      "topic_comment": "Causal methods in machine learning",
      "scheduling_comment": "Either Monday or Friday; will register based on scheduling.",
      "author": "Gregory Pottie",
      "authors_comment": "Sunay Bhat, Jeffrey Jiang, Omead Pooladzandi and Greg Pottie"
    },
    "109": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 109,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Jorge I. Poveda",
      "authors_comment": ""
    },
    "110": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 110,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Asit kumar Pradhan",
      "authors_comment": ""
    },
    "111": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 111,
      "title": "Improving Calibration through the Relationship with Adversarial Robustness",
      "abstract": "Neural networks lack adversarial robustness, i.e., they are vulnerable to adversarial examples that through small perturbations to inputs cause incorrect predictions. Further, trust is undermined when models give miscalibrated predictions, i.e., the predicted probability is not a good indicator of how much we should trust our model. In this paper, we study the connection between adversarial robustness and calibration and find that the inputs for which the model is sensitive to small perturbations (are easily attacked) are more likely to have poorly calibrated predictions. Based on this insight, we examine if calibration can be improved by addressing those adversarially unrobust inputs. To this end, we propose Adversarial Robustness based Adaptive Label Smoothing (AR-AdaLS) that integrates the correlations of adversarial robustness and calibration into training by adaptively softening labels for an example based on how easily it can be attacked by an adversary. We find that our method, taking the adversarial robustness of the in-distribution data into consideration, leads to better calibration over the model even under distributional shifts. In addition, AR-AdaLS can also be applied to an ensemble model to further improve model calibration.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Yao Qin",
      "authors_comment": "Yao Qin, Xuezhi Wang, Alex Beutel, Ed Chi"
    },
    "112": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 112,
      "title": "The statistical complexity of interactive decision-making",
      "abstract": "A fundamental challenge in interactive learning and decision making, ranging from bandit problems to reinforcement learning, is to provide sample-efficient, adaptive learning algorithms that achieve near-optimal regret. Characterizing the statistical complexity in this setting is challenging due to the interactive nature of the problem. We present a complexity measure, the Decision-Estimation Coefficient, that is proven to be both necessary and sufficient for interactive learning, as well as a unified algorithm design principle, Estimation-to-Decisions, which transforms any algorithm for estimation into an online algorithm for decision making. This complexity measure is inherently information-theoretic, and it unifies a number of existing approaches -- both Bayesian and frequentist.",
      "topic_comment": "online decision-making",
      "scheduling_comment": "Tue/Wed/Thu works well, with slight preference for Tue/Thu.",
      "author": "Alexander Rakhlin",
      "authors_comment": "D. Foster, S. Kakade, and J. Qian"
    },
    "113": {
      "attending_days": [
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 113,
      "title": "Stabilizability of Vector Systems with Uniform Actuation Unpredictability",
      "abstract": "Control strategies for vector systems typically depend on the controller’s ability to plan out future control actions.\nHowever, in the case where model parameters are random and time-varying, this planning might not be possible.\nThis paper explores the fundamental limits of a simple system, inspired by the intermittent Kalman filtering model,\nwhere the actuation direction is drawn uniformly from the unit hypersphere. The model allows us to focus on a fundamental tension in the control of underactuated vector systems — the need to balance the growth of the system in different dimensions. We characterize the stabilizability of d-dimensional systems with symmetric gain matrices by providing tight necessary and sufficient conditions that depend on the eigenvalues of the system. The proof technique relies on the fact that the second moment stability of the system can also be understood by examining any arbitrary weighted two-norm of the state.",
      "topic_comment": "control with model uncertainty",
      "scheduling_comment": "It would be great to have the talk on Thursday, sorry it's only one day. Also please don't schedule in the same slot as Anant Sahai, as one of us will have to be free to watch our baby.",
      "author": "Gireeja Ranade",
      "authors_comment": "Rahul Arya, Chih-Yuan Chiu, and Gireeja Ranade"
    },
    "114": {
      "attending_days": [
        "2022-05-23"
      ],
      "id": 114,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Ramesh Rao",
      "authors_comment": ""
    },
    "115": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 115,
      "title": "Tutorial on Quantum Error Correction and Recent Developments in Quantum LDPC Codes-II",
      "abstract": "In the second part, we introduce important families of quantum low-density parity-check codes (QLDPC) codes and argue why the LDPC property is even more critical for quantum applications than for today’s classical applications. Briefly, review methods to construct these codes, achieve optimal parameters and decode them. This includes a discussion on efficient iterative message passing decoders, their associated quantum trapping sets, the degeneracy problem, scenarios when syndromes themselves are noisy, and the potential for machine learning techniques to aid in decoding.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Nithin Raveendran",
      "authors_comment": ""
    },
    "116": {
      "attending_days": [
        "2022-05-23"
      ],
      "id": 116,
      "title": "Learning Latent Causal Graphs Via Mixture Oracles",
      "abstract": "We study the problem of reconstructing a causal graphical model from data in the\npresence of latent variables. The main problem of interest is recovering the causal structure\nover the latent variables while allowing for general, potentially nonlinear dependencies.\nIn many practical problems, the dependence between raw observations (e.g. pixels in\nan image) is much less relevant than the dependence between certain high-level, latent\nfeatures (e.g. concepts or objects), and this is the setting of interest. We provide conditions\nunder which both the latent representations and the underlying latent causal model are\nidentifiable by a reduction to a mixture oracle. These results highlight an intriguing\nconnection between the well-studied problem of learning the order of a mixture model and\nthe problem of learning the bipartite structure between observables and unobservables.\nThe proof is constructive, and leads to several algorithms for explicitly reconstructing the\nfull graphical model. We discuss efficient algorithms and provide experiments illustrating\nthe algorithms in practice.",
      "topic_comment": "learning, causal, Bayesian networks, graphical models",
      "scheduling_comment": "Monday",
      "author": "Pradeep Ravikumar",
      "authors_comment": "Bohdan Kivva, Goutham Rajendran, Pradeep Ravikumar, Bryon Aragam."
    },
    "117": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25"
      ],
      "id": 117,
      "title": "Reed-Muller Codes Achieve Capacity on BMS Channels (Part 2)",
      "abstract": "TBD",
      "topic_comment": "",
      "scheduling_comment": "I will addend Monday - Wednesday. Henry Pfister and I plan to present one part each of this work. Please schedule sequentially with this time second.",
      "author": "Galen Reeves",
      "authors_comment": "Galen Reeves and Henry Pfister"
    },
    "118": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 118,
      "title": "Distributed Two-Time Scale Gradient Descent over Time-varying Networks",
      "abstract": "We study non-convex distributed optimization problems over time-varying networks, where a set of agents collaboratively solve a separable optimization problem. Practically, exchanging the exact information introduces a massive communication overhead. We introduce and study a two time-scale decentralized algorithm with a broad class of lossy information-sharing methods (including noisy, quantized, and/or compressed information sharing) over time-varying networks. In our method, one time-scale suppresses the (imperfect) incoming information from the neighboring agents, and one time-scale operates on local cost functions' gradients. We show that with a proper choice for the step-size parameters, the algorithm achieves a convergence rate of $\\mathcal{O}(T^{-1/3+\\epsilon})$ for non-convex distributed optimization problems over time-varying networks for any $\\epsilon>0$.",
      "topic_comment": "machine learning, distributed optimization, decentralized learning",
      "scheduling_comment": "Availability: Tuesday from 3:00 pm to Friday 12:00 pm",
      "author": "Hadi Reisizadeh",
      "authors_comment": "Hadi Reisizadeh, Behrouz Touri, and Soheil Mohajer"
    },
    "119": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 119,
      "title": "Tutorial on Quantum Error Correction and Recent Developments in Quantum LDPC Codes: Part I",
      "abstract": "In the first part, we introduce the basics of quantum information through the fundamental postulates of quantum mechanics. Then, discuss the stabilizer formalism for constructing quantum error correcting codes, which leads to the so-called stabilizer codes.\n\nIn the setting of quantum computing, see the dual objectives that these codes must accomplish: (a) detecting and correcting quantum errors frequently to prevent accumulation of noise, and (b) performing logical operations (or gates) on the encoded information in a fault-tolerant manner. While the literature contains a lot of strategies to achieve these objectives, discuss some of the important challenges that remain. Through this process, illuminate the differences from code design for classical applications.",
      "topic_comment": "Quantum error correction",
      "scheduling_comment": "",
      "author": "Narayanan Rengaswamy",
      "authors_comment": "Nithin Raveendran, Narayanan Rengaswamy, Bane Vasic, Dariush Divsalar, Mike Cheng, Samuel Dolinar"
    },
    "120": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 120,
      "title": "On Gaussian Bounds for Discrete Entropies",
      "abstract": "It is well known that the Gaussian distribution has the largest differential entropy amongst all distributions of equal variance. In this paper, we derive similar Gaussian (or generalized Gaussian) upper bounds for discrete entropies (or Rényi entropies) of integer-valued variables. Using a mixed discrete-continuous bounding technique and the Poisson summation formula from Fourier analysis, it is proved that in many cases, such Gaussian bounds hold with an additive term that vanishes exponentially as the variance increases. We compare these results with previous ones on the entropy, in particular bounds derived by Mow using the Jacobi theta function.",
      "topic_comment": "IT and Number Theory, Inequalities",
      "scheduling_comment": "Tuesday or Thursday",
      "author": "Olivier Rioul",
      "authors_comment": "Olivier Rioul"
    },
    "121": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 121,
      "title": "On the Robustness to Misspecification of α-Posteriors and Their Variational Approximations",
      "abstract": "Variational inference (VI) is a machine learning technique that approximates difficult-to-compute probability densities by using optimization. While VI has been used in numerous applications, it is particularly useful in Bayesian statistics where one wishes to perform statistical inference about unknown parameters through calculations on a posterior density. In this talk, I will review the core concepts of VI and introduce some new ideas about VI and robustness to model misspecification. In particular, we will study α-posteriors, which distort standard posterior inference by downweighting the likelihood, and their variational approximations. We will see that such distortions, if tuned appropriately, can outperform standard posterior inference when there is potential parametric model misspecification. This is joint work with Marco Avella Medina, José Luis Montiel Olea, and Amilcar Velez (https://arxiv.org/abs/2104.08324).",
      "topic_comment": "statistics, Bayesian statistics, variational inference, statistical learning, machine learning",
      "scheduling_comment": "I can present Tuesday, Wednesday, or Thursday.",
      "author": "Cynthia Rush",
      "authors_comment": "Marco Avella Medina, José Luis Montiel Olea, Cynthia Rush, and Amilcar Velez"
    },
    "122": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 122,
      "title": "From Wyner's Common Information to Learning with Succinct Representation",
      "abstract": "A new bimodal generative model is proposed for generating conditional and joint samples, accompanied with a training method with learning a succinct bottleneck representation.\nThe proposed model, dubbed as the variational Wyner model, is designed based on two classical problems in network information theory---distributed simulation and channel synthesis---in which Wyner's common information arises as the fundamental limit on the succinctness of the common representation.\nThe model is trained by minimizing the symmetric Kullback--Leibler divergence between variational and model distributions with regularization terms for common information, reconstruction consistency, and latent space matching terms, which is carried out via an adversarial density ratio estimation technique.\nThe utility of the proposed approach is demonstrated through experiments for joint and conditional generation with synthetic and real-world datasets, as well as a challenging zero-shot image retrieval task.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Jongha Ryu",
      "authors_comment": "Jongha Jon Ryu, Yoojin Choi, Young-Han Kim, Mostafa El-Khamy, Jungwon Lee"
    },
    "123": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 123,
      "title": "Neural Estimation of the Rate-Distortion Function With Applications to Operational Source Coding",
      "abstract": "A fundamental question in designing lossy data compression schemes is how well one can do in comparison with the rate-distortion function, which describes the known theoretical limits of lossy compression. Motivated by the empirical success of deep neural network (DNN) compressors on large, real-world data, we investigate methods to estimate the rate-distortion function on such data, which would allow comparison of DNN compressors with optimality. While one could use the empirical distribution of the data and apply the Blahut-Arimoto algorithm, this approach presents several computational challenges and inaccuracies when the datasets are large and high-dimensional, such as the case of modern image datasets. Instead, we re-formulate the rate-distortion objective, and solve the resulting functional optimization problem using neural networks. We apply the resulting rate-distortion estimator, called NERD, on popular image datasets, and provide evidence that NERD can accurately estimate the rate-distortion function. Using our estimate, we show that the rate-distortion achievable by DNN compressors are within several bits of the rate-distortion function for real-world datasets. Additionally, NERD provides access to the rate-distortion achieving channel, as well as samples from its output marginal. Therefore, using recent results in reverse channel coding, we describe how NERD can be used to construct an operational one-shot lossy compression scheme with guarantees on the achievable rate and distortion. Experimental results demonstrate competitive performance with DNN compressors.",
      "topic_comment": "",
      "scheduling_comment": "I prefer to have the talk scheduled on Monday, Tuesday or Wednesday.",
      "author": "Shirin Saeedi Bidokhti",
      "authors_comment": "Eric Lei, Hamed Hassani, Shirin Saeedi Bidokhti"
    },
    "124": {
      "attending_days": [
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 124,
      "title": "The (dis)connection between training loss and generalization in overparameterized models",
      "abstract": "In learning, it would be great if knowing that the training loss is low would tell us something about generalization performance. In underparameterized models, getting formal bounds like this can be fruitful. However, as has been  widely observed, such bounds often become vacuous/tautological in the overparameterized regime. In this talk, we share some examples --- of the \"counterexample\" flavor --- driving this point home. These examples illustrate how even for models and training data where good generalization is possible, it is possible for learning algorithms to sever the connection between training loss and generalization performance.",
      "topic_comment": "overparameterized models; learning; cute counterexamples",
      "scheduling_comment": "The safest day for me is Thursday. Early on Friday (first session) could also work, but is not ideal. I absolutely cannot be scheduled at the same time as Gireeja Ranade because one of us has to watch our baby son.",
      "author": "Anant Sahai",
      "authors_comment": "Anant Sahai, Addhyan Narang, Vidya Muthukumar, Vignesh Subramanian, Daniel Hsu, Misha Belkin"
    },
    "125": {
      "attending_days": [
        "2022-05-26"
      ],
      "id": 125,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Aditya Sant",
      "authors_comment": ""
    },
    "126": {
      "attending_days": [
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 126,
      "title": "Jeffreys-Lindley paradox, regularization and online prediction",
      "abstract": "This talk begins with the well known episode illustrating a dichotomy between Bayesian and frequentist statistics, the Jeffreys-Lindley paradox. We will see how this paradox leads us into a very natural way about thinking about hypothesis testing problems in an online setting (with almost surely finitely many type I or type II errors), where one matches the complexity of the problem tackled to the amount of data at hand. In other words, regularization, albeit in an abstract form. What is more interesting, which this talk establishes, is that regularization is also a necessary feature of this setting. Indeed, this line of work is one thread that reexamines and strengthens results from a line of hypothesis-testing results starting from Dembo-Peres and Cover, culminating in a framework that presents different abstractions of regularization as the corner stone of understanding prediction and learning.",
      "topic_comment": "Online learning, Learning Theory, Inference and Statistics",
      "scheduling_comment": "All of Wed and Thu.",
      "author": "Narayana Santhanam",
      "authors_comment": "C. Wu and N. Santhanam"
    },
    "127": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24"
      ],
      "id": 127,
      "title": "Engineers without borders: The Role of Intelligent Decisions, Information Acquisition and Spread in Containing Infectious Diseases",
      "abstract": "Prevention constitutes an essential component of containment of infection diseases. Intelligent decision making, information acquisition and spread help design prevention frameworks that are substantially more effective than traditional versions. Prevention can be accomplished through diagnostic testing and subsequent quarantining and immunization. Both these involve decision processes such as who should be prioritized for tested and/or immunization. For example, should we test only those who are in the immediate vicinity of those infected or those who have been in multi-hop contacts of those infected or those who are much farther off. Testing the second category may help preemptive diagnosis and the last category may stop silent asymptomatic spread before large clusters of infected individuals appear. Similarly, should we preferentially vaccinate those who have large and dynamically evolving contact sets to reduce transmission or those at greater risks of developing severe forms of the disease to protect the vulnerable. Does it always hold  that preferentially immunizing the vulnerable minimize overall death and hospitalization counts ? These questions arise when the testing and vaccination capacities are limited and lead to tradeoffs between exploration and exploitation. Finally, spread of infectious disease depends on behavioral patterns and opinions, eg, willingness to be vaccinated, tested or maintaining social distancing. Accordingly, the  joint spread of infectious diseases and opinions over overlapping though not identical networks of physical and social contacts need to be understood and must inform intelligent testing and vaccination strategies. We present a wide variety of decision problems that naturally arise in these contexts and the solution frameworks that rely on optimal control, diffusion evolutions and learning.",
      "topic_comment": "infectious disease, network science, online learning, diffusion, optimal control",
      "scheduling_comment": "Monday, Tuesday",
      "author": "Saswati Sarkar",
      "authors_comment": "Shirin Saeedi Bidokhti, Xingran Chen, Jungyeol Kim, Hesam Nikpey,"
    },
    "128": {
      "attending_days": [],
      "id": 128,
      "title": "A data-centric view on robustness",
      "abstract": "Over the past few years, researchers have proposed many ways to measure the robustness of machine learning models. In the first part of the talk, we will survey the current robustness landscape based on a large-scale experimental study involving more than 200 different models and test conditions. Despite the large variety of test conditions, common trends emerge: (i) robustness to natural distribution shift and synthetic perturbations are distinct phenomena, (ii) current algorithmic techniques have little effect on robustness to natural distribution shifts, (iii) training on more diverse datasets offers robustness gains on several natural distribution shifts.\n\nIn the second part of the talk, we then leverage the aforementioned insights to improve OpenAI’s CLIP model. CLIP achieved unprecedented robustness on several natural distribution shifts, but only when used as a zero-shot model. The zero-shot evaluation precludes the use of extra data for fine-tuning and hence leads to lower performance when there is a specific task of interest. To address this issue, we introduce a simple yet effective method for fine-tuning zero-shot models that leads to large robustness gains on several distribution shifts without reducing in-distribution performance.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Ludwig Schmidt",
      "authors_comment": ""
    },
    "129": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24"
      ],
      "id": 129,
      "title": "Channel Estimation for Reconfigurable Intelligent Surfaces -- Challenges and Capacity Implication",
      "abstract": "Reconfigurable intelligent surfaces (RIS) consist of a large collection of small reflectors whose reflection coefficients can be controlled by various means, e.g., reactive elements or switches changing reflector shapes. RIS are not encumbered by the encoding and decoding circuitry of a relay, or the required emitted power, and thus are emerging as a cost-effective method for controlling signals and interference in a wireless network. The rapidly expanding literature on RIS signifies serious potential, but several fundamental challenges have also been identified. One of them is channel estimation, because a passive RIS can neither generate nor directly detect pilots, one must rely on estimating the cascade channel whose dimension quickly gets out of hand. This talk explores the implications on channel estimation and the RIS-induced channel capacity.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Bharath Shamasundar",
      "authors_comment": "Bharath Shamasundar and Aria Nosratinia"
    },
    "130": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25"
      ],
      "id": 130,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Joni Shaska",
      "authors_comment": ""
    },
    "131": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24"
      ],
      "id": 131,
      "title": "Random Orthogonalization for Federated Learning in Massive MIMO Systems",
      "abstract": "We propose a novel uplink communication method, coined random orthogonalization, for federated learning (FL) in a massive multiple-input and multiple-output (MIMO) wireless system. The key novelty of random orthogonalization comes from the tight coupling of FL model aggregation and two unique characteristics of massive MIMO - channel hardening and favorable propagation. As a result, random orthogonalization can achieve natural over-the-air model aggregation without requiring transmitter side channel state information, while significantly reducing the channel estimation overhead at the receiver. Theoretical analyses with respect to both communication and machine learning performances are carried out. In particular, an explicit relationship among the convergence rate, the number of clients and the number of antennas is established. Experimental results validate the effectiveness and efficiency of random orthogonalization for FL in massive MIMO.",
      "topic_comment": "Federated Learning",
      "scheduling_comment": "Monday or Tuesday",
      "author": "Cong Shen",
      "authors_comment": "Xizixiang Wei, Cong Shen, Jing Yang, and H. Vincent Poor"
    },
    "132": {
      "attending_days": [
        "2022-05-27"
      ],
      "id": 132,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Yuanyuan Shi",
      "authors_comment": ""
    },
    "133": {
      "attending_days": [
        "2022-05-23"
      ],
      "id": 133,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Paul Siegel",
      "authors_comment": ""
    },
    "134": {
      "attending_days": [
        "2022-05-23"
      ],
      "id": 134,
      "title": "Implementing Optimization-Based Control Tasks with Limited Computing Capacity",
      "abstract": "A common aspect of today's cyber-physical systems is that multiple optimization-based control tasks may execute in a shared processor. Such control tasks make use of online optimization, and thus have large execution times; hence, their sampling periods must be large as well to satisfy real-time schedulability conditions. However, the larger the sampling period is, the worse the control performance will be. The goal of our work is to develop a robust to early termination optimization approach that can be used to effectively solve real-time optimization problems involved in controlling the system despite the presence of unpredictable, variable, and limited computing capacity. The significance of the developed approach is that the optimization iterations can be stopped at any time instant with a guaranteed feasible solution; as a result, optimization-based control tasks can be implemented using a smaller sampling period with a consequent lower degradation in the control performance.",
      "topic_comment": "Optimization, control, real-time scheduling",
      "scheduling_comment": "monday or tuesday would preferable days",
      "author": "Bruno Sinopoli",
      "authors_comment": "Mehdi Hosseinzadeh, Ilya Kolmanovsky, Sanjoy Baruah, Bruno Sinopoli"
    },
    "135": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 135,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Maciej Skorski",
      "authors_comment": ""
    },
    "136": {
      "attending_days": [
        "2022-05-27"
      ],
      "id": 136,
      "title": "LightSecAgg: a Lightweight and Versatile Design for Secure Aggregation in Federated Learning",
      "abstract": "Secure model aggregation is a key component of federated learning (FL) that aims at protecting the privacy of each user's individual model while allowing for their global aggregation. It can be applied to any aggregation-based FL approach for training a global or personalized model. Model aggregation needs to also be resilient against likely user dropouts in FL systems, making its design substantially more complex. State-of-the-art secure aggregation protocols rely on secret sharing of the random-seeds used for mask generations at the users to enable the reconstruction and cancellation of those belonging to the dropped users. The complexity of such approaches, however, grows substantially with the number of dropped users. We propose a new approach, named LightSecAgg, to overcome this bottleneck by changing the design from \"random-seed reconstruction of the dropped users\" to \"one-shot aggregate-mask reconstruction of the active users via mask encoding/decoding\". We show that LightSecAgg achieves the same privacy and dropout-resiliency guarantees as the state-of-the-art protocols while significantly reducing the overhead for resiliency against dropped users. We also demonstrate that, unlike existing schemes, LightSecAgg can be applied to secure aggregation in the asynchronous FL setting. Furthermore, we provide a modular system design and optimized on-device parallelization for scalable implementation, by enabling computational overlapping between model training and on-device encoding, as well as improving the speed of concurrent receiving and sending of chunked masks. We evaluate LightSecAgg via extensive experiments for training diverse models on various datasets in a realistic FL system with large number of users and demonstrate that LightSecAgg significantly reduces the total training time. \n\nFull paper, which will appear in MLSYS'22, is available online at https://arxiv.org/abs/2109.14236.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Jinhyun So",
      "authors_comment": ""
    },
    "137": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 137,
      "title": "Analog Secret Sharing with Applications to Private  Distributed Computing",
      "abstract": "We consider the critical problems of distributed computing over data while keeping it private from the computational servers. The state-of-the-art approaches to this problem rely on quantizing the data into a finite field so that\nthe cryptographic approaches for secure multiparty computing can then be employed. In particular, Shamir’s secret sharing is one such building block that is often used to guarantee data privacy. These approaches, however, can\nresult in substantial accuracy losses due to fixed-point representation of the data and computation overflows. To address these critical issues, we propose a novel algorithm to solve the privacy-preserving distributed computing\nproblem when data is in the analog domain, e.g., the field of real/complex numbers. Specifically, we introduce Shamir’s secret sharing scheme analog counterpart that can be applied to real-valued data. We characterize the\nprivacy of the data from both information-theoretic and cryptographic perspectives while establishing a connection between the two notions in the analog domain. It is shown how the proposed framework can be adopted to\ndo computation tasks when data is represented using floating-point numbers.\nWe then show that this leads to a fundamental trade-off between the privacy level of data and the accuracy of the result. By extending the setup to distributed learning, we show how to train a machine learning model using the\nproposed algorithm while keeping the data and trained model private. Then numerical results are shown for experiments on several datasets.\nFurthermore, experimental advantages are shown compared to fixed-point implementations over finite fields.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Mahdi Soleymani",
      "authors_comment": ""
    },
    "138": {
      "attending_days": [
        "2022-05-27"
      ],
      "id": 138,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Sai Ashish Somayajula",
      "authors_comment": ""
    },
    "139": {
      "attending_days": [
        "2022-05-25",
        "2022-05-27"
      ],
      "id": 139,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Joseph Soriaga",
      "authors_comment": ""
    },
    "140": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 140,
      "title": "Group Testing on General Set-Systems",
      "abstract": "Group testing is one of the fundamental problems in coding theory and combinatorics in which one is to identify a subset of contaminated items from a given ground set. There has been renewed interest in group testing recently due to its applications in testing for viral deceases, such as pool testing for the novel coronavirus. The majority of existing works on group testing focus on the uniform setting in which any subset of size d from a ground set V of size n is potentially contaminated.\n\nIn this work, we consider a generalized version of group testing with an arbitrary set-system of potentially contaminated sets. The generalized problem is characterized by a hypergraph H=(V, E), where V represents the ground set and edges (\\math e\\in E) represent potentially contaminated sets. The problem of generalized group testing is motivated by practical settings in which not all subsets of a given size d may be potentially contaminated, rather, due to social dynamics, geographical limitations, or other considerations, there exist subsets that can be readily ruled out. For example, in the context of pool testing, the edge set E may consist of families, work teams, or students in a classroom, i.e, subsets likely to be mutually contaminated. The goal in studying the generalized setting is to leverage the additional knowledge characterized by H=(V, E) to significantly reduce the number of required tests.",
      "topic_comment": "Group testing",
      "scheduling_comment": "Tuesday, Thursday, or Friday",
      "author": "Alex Sprintson",
      "authors_comment": "Mira Gonen, Michael Langberg and Alex Sprintson"
    },
    "141": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25"
      ],
      "id": 141,
      "title": "Neural Estimation of  Statistical Divergences",
      "abstract": "Statistical divergences (SDs), which quantify the dissimilarity between probability distributions, are a basic constituent of statistical inference and machine learning. A modern method for estimating those divergences relies on parametrizing an empirical variational form by a neural network (NN) and optimizing over parameter space. Such neural estimators are abundantly used in practice, but corresponding performance guarantees are partial and call for further exploration. We establish non-asymptotic absolute error bounds for a neural estimator realized by a shallow NN, focusing on four popular f-divergences---Kullback-Leibler, chi-squared, squared Hellinger, and total variation. \nOur analysis relies on non-asymptotic function approximation theorems and tools from empirical process theory to bound the two sources of error involved: function approximation and empirical estimation. The bounds characterize the effective error in terms of NN size and the number of samples, and reveal scaling rates that ensure consistency. For compactly supported distributions, we further show that neural estimators of the first three divergences above with appropriate NN growth-rate are minimax rate-optimal, achieving the parametric convergence rate.",
      "topic_comment": "Neural estimation, minimax risk, f divergences, approximation",
      "scheduling_comment": "I am attending on Monday, Tuesday and Wednesday.",
      "author": "Sreejith Sreekumar",
      "authors_comment": "Sreejith Sreekumar and Ziv Goldfeld"
    },
    "142": {
      "attending_days": [
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 142,
      "title": "An asymptotic multiclass classification generalization \"rate region\" for overparameterized linear models",
      "abstract": "We set up an overparameterized linear model with Gaussian features in an asymptotic setting where both the number of underlying features and the number of classes scale with the number of training points.  We provide conditions for asymptotically good generalization for multiclass classification of minimum-norm interpolating solutions in this model. The survival/contamination analysis framework for understanding the behavior of overparameterized learning problems is adapted to this setting, leveraging a style of arguments in the spirit of typicality arguments in information theory that avoid overly conservative union bounds. This reveals that multiclass classification qualitatively behaves like binary classification in that, as long as there are not too many classes and it is possible to generalize well even in some settings where the corresponding regression tasks would not generalize. Besides various technical challenges, it turns out that the key difference from the binary classification setting is that there are relatively fewer positive training examples of each class in the multiclass setting as the number of classes increases, making the multiclass problem ``harder'' than the binary one.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Vignesh Subramanian",
      "authors_comment": ""
    },
    "143": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 143,
      "title": "Common Information and Gray-Wyner Network",
      "abstract": "The rate region of lossy Gaussian Gray-Wyner network for symmetric distortion is computed by showing Gaussian optimality of the auxiliary for the non-convex optimization problem. Besides, we provide upper and lower Shannon type bounds for any source and in particular the bounds partially meet when the source is modeled as additive Gaussian noise. A novel measure of common information with operational meaning in the Gray-Wyner network is introduced. Moreover, this measure is used to extract common information between datasets, referred to as Common Information Components Analysis.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Erixhen Sula",
      "authors_comment": "Erixhen Sula and Michael Gastpar"
    },
    "144": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 144,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Zhongchang Sun",
      "authors_comment": ""
    },
    "145": {
      "attending_days": [
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 145,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Puoya Tabaghi",
      "authors_comment": ""
    },
    "146": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25"
      ],
      "id": 146,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Ido Tal",
      "authors_comment": ""
    },
    "147": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-27"
      ],
      "id": 147,
      "title": "Data Freshness Oriented Sampling Under Unknown Delay Statistics",
      "abstract": "In this presentation, we will revisit data-freshness oriented sampling problem for a transmitter-receiver pair with random communication delay. The goal is to design a sampling strategy in the absence of delay distribution statistics. When the evoluation of the time-varying source is unavailable to the sampler, we use the Age of Information, namely the time elapsed since the latest information has been generated, to capture information freshness at the receiver. We propose an online algorithm that minimizes the AoI performance. We prove that the algorithm learns the optimum strategy almost surely, and the learning rate is minimax order optimal. Next, we turn to sampling problem for the Wiener process. We propose online algorithm that minimizes the MSE and show its optimality.",
      "topic_comment": "Age of Information, Wireless Communication, Online Learning",
      "scheduling_comment": "Graduation Day.",
      "author": "Haoyue Tang",
      "authors_comment": "Haoyue Tang, Yuchao Chen, Yin Sun, Leandros Tassiulas, Jintao Wang, Pengkun Yang"
    },
    "148": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 148,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Michelle Thompson",
      "authors_comment": ""
    },
    "149": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 149,
      "title": "Sensing Echoes: Temporal Misalignment as the Earliest Marker of Neurodevelopmental Derail",
      "abstract": "Neurodevelopmental disorders are on the rise worldwide, with diagnoses that detect derail from typical milestones by 3-4.5 years of age. By then, the circuitry in the brain has already reached some level of maturation that inevitably takes neurodevelopment through a different course. There is a critical need then to develop analytical methods that detect problems much earlier and identify targets for treatment. We here integrate data from multiple sources, including neonatal auditory brainstem evoked responses, clinical criteria detecting autism years later in those neonates and openly accessible genomics data, to produce the earliest known digital screening biomarker with underlying population-based genomics information, to flag neurodevelopmental derail in neonates. This work also defines concrete targets for treatment and offers a unifying statistical approach to aid guide a personalized course of maturation in line with the highly nonlinear, accelerated neurodevelopmental rates of change in early infancy.",
      "topic_comment": "Stochastic analyses, Mutual Information, Independence Test, Application of Graphical models, Chow-Liu Maximal Spanning Tree",
      "scheduling_comment": "Any day will work as I am attending the full meeting",
      "author": "Elizabeth Torres",
      "authors_comment": "Elizabeth B Torres, Thodoris Bermperidis, Hannah Varkey, Joe Vero, Eric London, Ha Phan, Phyllis Kittler, Anne Gordon, Simon Schafer, Fred H Gage, Terrence Sejnowski"
    },
    "150": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 150,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Behrouz Touri",
      "authors_comment": ""
    },
    "151": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 151,
      "title": "DP-REC: private and communication-efficient federated learning",
      "abstract": "Privacy and communication efficiency are important challenges in federated training of neural networks, and combining them is still an open problem. In this work, we develop a method that unifies highly compressed communication and differential privacy (DP). We introduce a compression technique based on Relative Entropy Coding (REC) to the federated setting. With a minor modification to REC, we obtain a provably differentially private learning algorithm, DP-REC, and show how to compute its privacy guarantees. Our experiments demonstrate that DP-REC drastically reduces communication costs while providing privacy guarantees comparable to the state-of-the-art.",
      "topic_comment": "Machine learning, privacy, federated learning, compression",
      "scheduling_comment": "I would prefer the talk to be scheduled during Tuesday-Thursday.",
      "author": "Aleksei Triastcyn",
      "authors_comment": "Aleksei Triastcyn, Matthias Reisser, Christos Louizos"
    },
    "152": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25"
      ],
      "id": 152,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Ertem Tuncel",
      "authors_comment": ""
    },
    "153": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 153,
      "title": "Mean Estimation in Low and High Dimensions",
      "abstract": "This talk will discuss the fundamental statistical problem of estimating the mean of a distribution, as accurately as possible given samples from it. This problem arises both as a subcomponent of many algorithms, and also in practice as one of the most important data primitives when dealing with real-world data. While many variants and extensions of this problem have been proposed and analyzed, in this talk I will discuss two of the most iconic: 1) when the data comes from a real-valued distribution, and 2) when the data comes from a high-dimensional vector-valued distribution. In both cases, we achieve the first estimators whose accuracy is optimal to 1+o(1) factors, optimal in its dependence on the unknown (co-) variance of the underlying distribution, the number of samples n, and the desired confidence delta. I will highlight some of the crucial and novel analytical tools used in the analysis, and in particular, draw attention to a new \"vector Bernstein inequality\" which makes precise the intuition that sums of bounded independent random variables in increasingly high dimensions increasingly \"adhere to a spherical shell\". These results suggest several possible extensions in this large and active area of statistical estimation research.",
      "topic_comment": "\"data efficient algorithms\"; (multivariate) statistical estimation; sample complexity",
      "scheduling_comment": "",
      "author": "Paul Valiant",
      "authors_comment": "Jasper C.H. Lee and Paul Valiant"
    },
    "154": {
      "attending_days": [
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 154,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Harsh Vardhan",
      "authors_comment": ""
    },
    "155": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 155,
      "title": "Bio-inspired multi-agent coordination games with Poisson observations",
      "abstract": "Global games are a class of incomplete information games where the payoffs exhibit strategic complementarity leading to an incentive for the agents to coordinate their actions. Such games have been used to model scenarios in many socioeconomic phenomena, where the private signals available to the agents are typically assumed to be Gaussian. We study an instance of a global game where the agents observe Poisson random variables, which are inspired by microbiology applications where information signals are disseminated via discrete molecular signals rather than continuous. Although this observation model violates the essential technical assumptions present in the Gaussian case, we present preliminary results on the existence of Nash equilibria in pure threshold policies in two variants of the underlying random state-of-the-world: an arbitrarily distributed discrete binary state and a continuous state with an (improper) uniform distribution.",
      "topic_comment": "Game theory, stochastic systems, systems biology",
      "scheduling_comment": "Monday to Thursday",
      "author": "Marcos Vasconcelos",
      "authors_comment": "Marcos M. Vasconcelos"
    },
    "156": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25"
      ],
      "id": 156,
      "title": "Fast Low Rank column-wise Compressive Sensing",
      "abstract": "This work studies the following problem: how to recover an $n \\times q$ rank-$r$ matrix, $\\Xstar  =[\\xstar_1 , \\xstar_2, ..., \\xstar_q]$ from $m$ independent linear projections of each of its $q$ columns, i.e., from $\\y_k := \\A_k \\xstar_k ,  k  \\in [q]$, when $\\y_k$ is an $m$-length vector. The matrices $\\A_k$ are known and mutually independent for different $k$. The regime of interest is low-rank  and undersampled measurements. Even though many LR recovery problems have been extensively studied in the last decade, this problem had received no attention in the theoretical literature until 2019. We introduce a novel gradient descent (GD) based solution called AltGD-Min. We show that, if all entries of all $\\A_k$s are i.i.d. Gaussian, and if the right singular vectors of $\\Xstar$ satisfy the incoherence assumption, then $\\epsilon$-accurate recovery of $\\Xstar$ is possible with $mq > C (n+q) r^2 \\log(1/\\epsilon)$ total samples and $O( mq nr \\log (1/\\epsilon))$ time. Compared with existing work, this is the fastest solution and, for $\\epsilon < 1/\\sqrt{r}$, it also has the best sample complexity.\n Moreover, we show that a simple extension of our approach also provably solves LR Phase Retrieval (LRPR), which is the magnitude-only generalization of LRcCS.\n\nAltGD-Min expresses $\\X = \\U\\B$ where $\\U$ and $\\B$ are matrices with $r$ columns and rows respectively, and alternates between a projected GD step for updating $\\U$, and a minimization step for updating $\\B$. For our problems, each iteration of AltGD-Min is as fast as that of regular (projected) GD, while its convergence rate is almost as fast as that of an AltMin solution. Moreover, its communication complexity (in case of a federated implementation) is much lower than that of  regular (projected) GD.",
      "topic_comment": "sparsity, low-rank matrix learning",
      "scheduling_comment": "Tuesday (preferred)  or else Wednesday morning",
      "author": "Namrata Vaswani",
      "authors_comment": "Seyedehsara (Sara) Nayer and Namrata Vaswani"
    },
    "157": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 157,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Ashwin Verma",
      "authors_comment": ""
    },
    "158": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25"
      ],
      "id": 158,
      "title": "Blockchain Systems and Mean Field Games",
      "abstract": "In this talk, I will illustrate example applications of Mean Field Games to Blockchains. We walk through its use in determining mining incentives for blockchain protocols in detail, and briefly touch on its use for decentralized finance (DeFi) systems.",
      "topic_comment": "Blockchains",
      "scheduling_comment": "Sometime Monday through Wednesday would be great",
      "author": "Sriram Vishwanath",
      "authors_comment": "Yanni Georghiades, Rajesh Mishra, Abhishek Shende and Sriram Vishwanath"
    },
    "159": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 159,
      "title": "Exponential Reduction in Sample Complexity with Learning of Ising Model Dynamics",
      "abstract": "The usual setting for learning the structure and parameters of graphical models assumes the availability of independent samples produced from the corresponding multivariate probability distribution. However, for many models the mixing time of the respective Markov chain can be very large and i.i.d. samples may not be obtained. We study the problem of reconstructing binary graphical models from correlated samples produced by a dynamical process. We analyze the sample complexity of two estimators that are based on the interaction screening objective and the conditional likelihood loss. We observe that for samples coming from a dynamical process far from equilibrium, the sample complexity reduces exponentially compared to a dynamical process that mixes quickly.",
      "topic_comment": "Graphical model learning, Markov random field learning.",
      "scheduling_comment": "I would like to avoid presenting on Friday",
      "author": "Marc Vuffray",
      "authors_comment": "Arkopal Dutt, Andrey Lokhov, Marc Vuffray, Sidhant Misra"
    },
    "160": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 160,
      "title": "Modeling and correcting bias in sequential evaluation",
      "abstract": "We consider the problem of sequential evaluation, in which an evaluator observes candidates in a sequence and assigns scores to these candidates in an online, irrevocable fashion. Motivated by the psychology literature that has studied sequential bias in such settings -- namely, dependencies between the evaluation outcome and the order in which the candidates appear -- we propose a natural model for the evaluator's rating process that captures the lack of calibration inherent to such a task. We conduct crowdsourcing experiments to demonstrate various facets of our model. We then proceed to study how to correct sequential bias under our model by posing this as a statistical inference problem. We propose a near-linear time, online algorithm for this task and prove guarantees in terms of two canonical ranking metrics, matched with lower bounds demonstrating optimality in a certain sense. Our algorithm outperforms the de facto method of using the rankings induced by the reported scores.",
      "topic_comment": "ranking, statistical estimation, crowdsourcing, fairness",
      "scheduling_comment": "I can present Monday through Thursday.",
      "author": "Jingyan Wang",
      "authors_comment": "Jingyan Wang, Ashwin Pananjady"
    },
    "161": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 161,
      "title": "Soft BIBD and Product Gradient Codes",
      "abstract": "Gradient coding is a coding theoretic framework to provide robustness against slow or unresponsive machines, known as stragglers, in distributed machine learning applications. Recently, Kadhe et al. proposed a gradient code based on a combinatorial design, called balanced incomplete block design (BIBD), which is shown to outperform many existing gradient codes in worst-case adversarial straggling scenarios. However, parameters for which such BIBD constructions exist are very limited. In this paper, we aim to overcome such limitations and construct gradient codes which exist for a wide range of system parameters while retaining the superior performance of BIBD gradient codes. Two such constructions are proposed, one based on a probabilistic construction that relax the stringent BIBD gradient code constraints, and the other based on taking the Kronecker product of existing gradient codes. The proposed gradient codes allow flexible choices of system parameters while retaining comparable error performance.",
      "topic_comment": "gradient coding, distributed computing, combinatorial design",
      "scheduling_comment": "Monday, Tuesday",
      "author": "Lele Wang",
      "authors_comment": "Animesh Sakorikar and Lele Wang"
    },
    "162": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 162,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Yue Wang",
      "authors_comment": ""
    },
    "163": {
      "attending_days": [
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 163,
      "title": "Data Privacy and Protection on Deep Leakage from Gradients by Layer-Wise Pruning",
      "abstract": "In this paper, we study a data privacy and protection problem in a federated learning system for image classification. We assume that an attacker has full knowledge of the shared gradients during the model update. We propose a layer-wise pruning defense to prevent data leakage to the attacker. We also propose a sequential update attack method, which accumulates the information across training epochs. Simulation results show that the sequential update can gradually improve the image reconstruction results for the attacker. Moreover, the layer-wise pruning method is shown to be more efficient than classical threshold-based pruning on the shared gradients.",
      "topic_comment": "Federated learning privacy",
      "scheduling_comment": "I will attend Tuesday, Wednesday, and Thursday",
      "author": "Ye Wang",
      "authors_comment": "Bryan Liu, Toshiaki Koike-Akino, Ye Wang, Kyeong Jin Kim, Matthew E. Brand, Shuchin Aeron, Kieran Parsons"
    },
    "164": {
      "attending_days": [
        "2022-05-23"
      ],
      "id": 164,
      "title": "Random access throughput correlation of wireless radios with random contention probabilities",
      "abstract": "The instantaneous throughput under random access (here, the classical collision model)  is \"doubly stochastic\", with randomness from both the environment and the individual user random contention decisions.  This paper treats the n user contention probabilities, q1,...,qn, as independent and identically distributed (IID) random variables and derives an expression for the correlation between the random throughput and the (random) conditional expected throughput, conditioned on q1,...,qn, in terms of n and the mean and variance of q1,...,qn.  This enables quantification and analysis of the relative contribution of both the environment and contention randomness in the throughput.",
      "topic_comment": "Random access; wireless communications; throughput; performance analysis.",
      "scheduling_comment": "I am only able to attend and present on Monday May 23.  I need to return to teach Tuesday morning and run a department faculty meeting Tuesday afternoon.  Apologies for the tight constraints.  Thank you, Steve.",
      "author": "Steven Weber",
      "authors_comment": "Hariharan Narayanan; Steven Weber."
    },
    "165": {
      "attending_days": [
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 165,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Honghao Wei",
      "authors_comment": ""
    },
    "166": {
      "attending_days": [
        "2022-05-27"
      ],
      "id": 166,
      "title": "FedHybrid: a hybrid federated optimization method for heterogeneous clients",
      "abstract": "We consider a distributed consensus optimization problem over a server-client (federated) network, where all clients are connected to a central server. Current distributed algorithms fail to capture the heterogeneity in clients' local computation capacities. Motivated by the method of multipliers in centralized optimization, we derive a Newton-type primal-dual method with a distributed implementation utilizing the server-client topology. Then we propose FedHybrid as a hybrid primal-dual method that allows heterogeneous clients to perform different types of updates. Specifically, those clients with higher computational capabilities and/or cheaper costs to perform computation can implement Newton-type updates locally, while other clients can adopt much simpler gradient-type updates. Theoretically, we propose a novel merit function by combining the dual optimality gap and the primal tracking error. We prove that FedHybrid converges linearly to the exact optimal point for strongly convex functions, regardless of clients' choices of gradient-type or Newton-type updates. Finally, we show numerical studies to demonstrate the efficacy of our method in practice. To the best of our knowledge, this is the first hybrid method allowing heterogeneous local updates for distributed consensus optimization with provable convergence and rate guarantees.",
      "topic_comment": "Federated learning",
      "scheduling_comment": "Monday or Friday would be the best with a slight preference for Friday",
      "author": "Ermin Wei",
      "authors_comment": "Xiaochun Niu, Ermin Wei"
    },
    "167": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 167,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Peggy Wells",
      "authors_comment": ""
    },
    "168": {
      "attending_days": [
        "2022-05-23"
      ],
      "id": 168,
      "title": "Memory Bounds for the Experts Problem",
      "abstract": "Online learning with expert advice is a fundamental problem of sequential prediction. In this problem, the algorithm has access to a set of n \"experts\" who make predictions on each day. The goal on each day is to process these predictions, and make a prediction with the minimum cost. After making a prediction, the algorithm sees the actual outcome on that day, updates its state, and then moves on to the next day. An algorithm is judged by how well it does compared to the best expert in the set. \n \nThe classical algorithm for this problem is the multiplicative weights algorithm. However, every application, to our knowledge, relies on storing weights for every expert, and uses Omega(n) memory. There is little work on understanding the memory required to solve the online learning with expert advice problem in natural streaming models, which is important when the number of experts, as well as the number of days on which the experts make predictions, is large. \n \nWe initiate the study of the learning with expert advice problem in the streaming setting, and show lower and upper bounds. Our lower bound for i.i.d., random order, and adversarial order streams uses a novel masking technique and distributional detection game to show a smooth trade-off for regret versus memory. Our upper bounds in adversarial and random-order streams show ways to run standard sequential prediction algorithms in rounds on small \"pools\" of experts, thus reducing the necessary memory. For random-order streams, we show that our upper bound is tight up to low order terms.",
      "topic_comment": "online learning",
      "scheduling_comment": "I would need to do very late Monday afternoon / evening, since I'll be flying in around noon.",
      "author": "David Woodruff",
      "authors_comment": "Vaidehi Srinivas, David P. Woodruff, Ziyu (Neil) Xu, and Samson Zhou"
    },
    "169": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26"
      ],
      "id": 169,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Yu Xiang",
      "authors_comment": ""
    },
    "170": {
      "attending_days": [
        "2022-05-27"
      ],
      "id": 170,
      "title": "Neural Tangent Kernel Maximum Mean Discrepancy",
      "abstract": "We present a novel neural network Maximum Mean Discrepancy (MMD) statistic by identifying a new connection between neural tangent kernel (NTK) and MMD. This connection enables us to develop a computationally efficient and memory-efficient approach to compute the MMD statistic and perform NTK based two-sample tests towards addressing the long-standing challenge of memory and computational complexity of the MMD statistic, which is essential for online implementation to assimilating new samples. Theoretically, such a connection allows us to understand the NTK test statistic properties, such as the Type-I error and testing power for performing the two-sample test, by adapting existing theories for kernel MMD. Numerical experiments on synthetic and real-world datasets validate the theory and demonstrate the effectiveness of the proposed NTK-MMD statistic.",
      "topic_comment": "Machine learning, maximum mean divergence (MMD), neural tangent kernel",
      "scheduling_comment": "",
      "author": "Yao Xie",
      "authors_comment": "Xiuyuan Cheng and Yao Xie"
    },
    "171": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 171,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Xiangxiang Xu",
      "authors_comment": ""
    },
    "172": {
      "attending_days": [
        "2022-05-23",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 172,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Mengwei Yang",
      "authors_comment": ""
    },
    "173": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24"
      ],
      "id": 173,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Jing Yang",
      "authors_comment": ""
    },
    "174": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 174,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Hanwen Yao",
      "authors_comment": ""
    },
    "175": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 175,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Chaorui Yao",
      "authors_comment": ""
    },
    "176": {
      "attending_days": [],
      "id": 176,
      "title": "A deterministic algorithm for computing the weight distribution of polar codes",
      "abstract": "In this work, we present a deterministic algorithm for computing the entire weight distribution of polar codes. As the first step, we derive an efficient recursive procedure to compute the weight distribution that arises in successive cancellation decoding of polar codes along any decoding path. This solves the open problem recently posed by Polyanskaya, Davletshin, and Polyanskii. Using this recursive procedure, at code length n, we can compute the entire weight distribution of certain polar cosets in time O(n^2). We show that any polar code can be represented as a disjoint union of such polar cosets; moreover, this representation extends to polar codes with dynamically frozen bits. This implies that our methods can be also used to compute the weight distribution of general linear codes. However, the number of polar cosets in such representation scales exponentially with a parameter introduced herein, which we call the mixing factor. To upper bound the complexity of our algorithm, we identify polar codes as decreasing monomial codes, and study the range of their mixing factors. We prove that among all decreasing monomial codes with rates at most 1/2, self-dual Reed-Muller codes have the largest mixing factors. To further reduce the exponential complexity of our algorithm, we make use of the fact that, as decreasing monomial codes, polar codes have a large automorphism group, which includes the lower-triangular affine group LTA(m, 2). We prove that LTA(m, 2) acts transitively on certain subsets of the codes, thereby drastically reducing the number of polar cosets we need to evaluate. This complexity reduction makes it possible to compute the weight distribution of any polar code of length up to n = 128.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Hanwen Yao",
      "authors_comment": ""
    },
    "177": {
      "attending_days": [
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 177,
      "title": "Online “Safe” Learning and Decision Making: Optimism, Pessimism and Queue",
      "abstract": "Data-driven learning and decision making in complex systems are often subject to a variety of operational constraints such as safety, fairness, and budget constraints. The problem becomes particularly challenging when the constraints are unknown, sometimes adversarial, and must be learned while making decisions. This talk presents several recent results on this topic and explains the important roles of optimism, pessimism, and queue in online safe learning and decision making.",
      "topic_comment": "online learning and decision making, regret analysis, safe reinforcement learning",
      "scheduling_comment": "Thursday and Friday",
      "author": "Lei Ying",
      "authors_comment": "Hengquan Guo, Bin Li, Xin Liu, Pengyi Shi, Honghao Wei, and Lei Ying"
    },
    "178": {
      "attending_days": [
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 178,
      "title": "Overcoming the Long Horizon Barrier for Sample-Efficient Reinforcement Learning with Latent Low-Rank Structure",
      "abstract": "The practicality of reinforcement learning algorithms has been limited due to poor scaling with respect to the problem size, as the sample complexity of learning an epsilon-optimal policy scales with |S| times |A| over worst case instances of an MDP with state space S, action space A, and horizon H. A key question is when can we design probably efficient RL algorithms that exploit nice structure? We consider a class of MDPs that exhibit low rank structure, where the latent features are unknown. We argue that a natural combination of value iteration and low-rank matrix estimation results in an estimation error that grows doubly exponentially in the horizon length. We then provide a new algorithm along with statistical guarantees that efficiently exploits low rank structure given access to a generative model, achieving a sample complexity that scales linearly in |S|+|A| and polynomially in the horizon and the rank. In contrast to literature on linear and low-rank MDPs, we do not require a known feature mapping, our algorithm is computationally simple, and our results hold for long time horizons. Our results provide insights on the minimal low-rank structural assumptions required on the MDP with respect to the transition kernel versus the optimal action-value function.",
      "topic_comment": "reinforcement learning, matrix estimation",
      "scheduling_comment": "Thursday and Fridays, with a preference of Thursday > Friday morning > Friday afternoon",
      "author": "Christina Yu",
      "authors_comment": "Tyler Sam, Yudong Chen, Christina Lee Yu"
    },
    "179": {
      "attending_days": [],
      "id": 179,
      "title": "Approximately Equivariant Networks for Imperfectly Symmetric Dynamics",
      "abstract": "Incorporating symmetry as an inductive bias into neural network architecture has led to improvements in generalization, data efficiency, and physical consistency in dynamics modeling. Methods such as CNN or equivariant neural networks use weight tying to enforce symmetries such as shift invariance or rotational equivariance. However, despite the fact that physical laws obey many symmetries, real-world dynamical data rarely conforms to strict mathematical symmetry either due to noisy or incomplete data or to symmetry-breaking features in the underlying dynamical system. We explore approximately equivariant networks which are biased towards preserving symmetry but are not strictly constrained to do so. By relaxing equivariance constraints, we find that our models can outperform both baselines with no symmetry bias and baselines with overly strict symmetry in both simulated turbulence domains and real-world multi-stream jet flow.",
      "topic_comment": "deep learning, symmetry, dynamical systems",
      "scheduling_comment": "Wed / Friday morning",
      "author": "Rose Yu",
      "authors_comment": "Rui Wang, Robin Walters"
    },
    "180": {
      "attending_days": [
        "2022-05-24",
        "2022-05-27"
      ],
      "id": 180,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Jinjie Zhang",
      "authors_comment": ""
    },
    "181": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25",
        "2022-05-26",
        "2022-05-27"
      ],
      "id": 181,
      "title": "On Efficient Online Imitation Learning via Classification",
      "abstract": "Imitation learning (IL) is a general paradigm for learning in sequential decision making problems. Online imitation learning, where learners can interactively query for expert annotations, has been shown to achieve provably superior sample efficiency guarantees compared with its offline counterpart or reinforcement\nlearning.\n\nIn this work, we study classification-based online imitation learning (abbrev. COIL) and the fundamental feasibility to design oracle-efficient regret-minimization algorithms in this setting. Our contributions are: (1) we show that in the COIL setting, any proper online learning algorithm cannot guarantee a sublinear regret in general; (2) we propose LOGGER, an improper online learning algorithmic framework, that reduces COIL to online linear optimization, by utilizing a new concept named mixed policy class; (3) we design two oracle-efficient algorithms within the LOGGER framework that enjoy different sample complexity / interaction round complexity tradeoffs, and show their improvements over behavior cloning baselines; (4) we show that under standard complexity-theoretic assumptions, efficient dynamic regret minimization is infeasible in the LOGGER framework.",
      "topic_comment": "online learning; reinforcement learning; imitation learning; interactive learning",
      "scheduling_comment": "5/24, 5/25, 5/26, 5/27",
      "author": "Chicheng Zhang",
      "authors_comment": "Yichen Li and Chicheng Zhang"
    },
    "182": {
      "attending_days": [
        "2022-05-24",
        "2022-05-27"
      ],
      "id": 182,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Xinran Zheng",
      "authors_comment": ""
    },
    "183": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24",
        "2022-05-25"
      ],
      "id": 183,
      "title": "",
      "abstract": "",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Lizhong Zheng",
      "authors_comment": ""
    },
    "184": {
      "attending_days": [
        "2022-05-23",
        "2022-05-24"
      ],
      "id": 184,
      "title": "Assisted Learning for Organizations with Limited Data",
      "abstract": "We develop an assisted learning framework for assisting organization-level learners to improve their learning performance with limited and imbalanced data. In particular, learners at the organization level usually have sufficient computation resource, but are subject to stringent collaboration policy and information privacy. Their limited imbalanced data often cause biased inference and sub-optimal decision-making. In our assisted learning framework, an organizational learner purchases assistance service from a service provider and aims to enhance its model performance within a few assistance rounds. We develop effective stochastic training algorithms for assisted deep learning and assisted reinforcement learning. Different from existing distributed algorithms that need to frequently transmit gradients or models, our framework allows the learner to only occasionally share information with the service provider, and still achieve a near-oracle model as if all the data were centralized.",
      "topic_comment": "distributed learning, federated learning",
      "scheduling_comment": "",
      "author": "Yi Zhou",
      "authors_comment": "Cheng Chen, Jiaying Zhou, Jie Ding, Yi Zhou"
    },
    "185": {
      "attending_days": [
        "2022-05-25"
      ],
      "id": 185,
      "title": "Transition to Linearity of General Neural Networks with Directed Acyclic Graph Architecture",
      "abstract": "We show that feedforward neural networks corresponding to arbitrary directed acyclic graphs undergo transition to linearity as their  “width\" approaches infinity. The width of these general networks is characterized by the minimum in-degree of their neurons, except for the input and first layers. Our results identify the mathematical structure underlying transition to linearity and generalize a number of recent works aimed at characterizing transition to linearity or constancy of the Neural Tangent Kernel for standard architectures.",
      "topic_comment": "",
      "scheduling_comment": "",
      "author": "Libin Zhu",
      "authors_comment": ""
    }
  },
  "talksids": [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,
    40,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    58,
    59,
    60,
    61,
    62,
    63,
    64,
    65,
    66,
    67,
    68,
    69,
    70,
    71,
    72,
    73,
    74,
    75,
    76,
    77,
    78,
    79,
    80,
    81,
    82,
    83,
    84,
    85,
    86,
    87,
    88,
    89,
    90,
    91,
    92,
    93,
    94,
    95,
    96,
    97,
    98,
    99,
    100,
    101,
    102,
    103,
    104,
    105,
    106,
    107,
    108,
    109,
    110,
    111,
    112,
    113,
    114,
    115,
    116,
    117,
    118,
    119,
    120,
    121,
    122,
    123,
    124,
    125,
    126,
    127,
    128,
    129,
    130,
    131,
    132,
    133,
    134,
    135,
    136,
    137,
    138,
    139,
    140,
    141,
    142,
    143,
    144,
    145,
    146,
    147,
    148,
    149,
    150,
    151,
    152,
    153,
    154,
    155,
    156,
    157,
    158,
    159,
    160,
    161,
    162,
    163,
    164,
    165,
    166,
    167,
    168,
    169,
    170,
    171,
    172,
    173,
    174,
    175,
    176,
    177,
    178,
    179,
    180,
    181,
    182,
    183,
    184,
    185
  ],
  "tracks": {
    "tr_1": {
      "id": "tr_1",
      "sessions": [
        "s_5",
        "s_6",
        "s_7",
        "s_8"
      ]
    },
    "tr_2": {
      "id": "tr_2",
      "sessions": [
        "s_9",
        "s_10",
        "s_11",
        "s_12"
      ]
    },
    "tr_3": {
      "id": "tr_3",
      "sessions": [
        "s_13",
        "s_14",
        "s_15",
        "s_16"
      ]
    },
    "tr_4": {
      "id": "tr_4",
      "sessions": [
        "s_17",
        "s_18",
        "s_19",
        "s_20"
      ]
    },
    "tr_5": {
      "id": "tr_5",
      "sessions": [
        "s_21",
        "s_22",
        "s_23"
      ]
    },
    "tr_6": {
      "id": "tr_6",
      "sessions": [
        "s_25",
        "s_26"
      ]
    },
    "tr_7": {
      "id": "tr_7",
      "sessions": [
        "s_29",
        "s_30",
        "s_31",
        "s_32"
      ]
    },
    "tr_8": {
      "id": "tr_8",
      "sessions": [
        "s_33",
        "s_34",
        "s_35",
        "s_36"
      ]
    },
    "tr_9": {
      "id": "tr_9",
      "sessions": [
        "s_37",
        "s_38"
      ]
    },
    "tr_10": {
      "id": "tr_10",
      "sessions": [
        "s_41",
        "s_42"
      ]
    }
  },
  "tracksids": [
    "tr_1",
    "tr_2",
    "tr_3",
    "tr_4",
    "tr_5",
    "tr_6",
    "tr_7",
    "tr_8",
    "tr_9",
    "tr_10"
  ],
  "sessions": {
    "s_5": {
      "id": "s_5",
      "title": "Wireless Communication I",
      "talks": [
        30,
        66,
        164,
        129
      ]
    },
    "s_6": {
      "id": "s_6",
      "title": "Machine Learning I",
      "talks": [
        5,
        62,
        63
      ]
    },
    "s_7": {
      "id": "s_7",
      "title": "Efficient learning algorithms",
      "talks": [
        70,
        153,
        159
      ]
    },
    "s_8": {
      "id": "s_8",
      "title": "Theory II",
      "talks": [
        81,
        160,
        168
      ]
    },
    "s_9": {
      "id": "s_9",
      "title": "Coding Theory I",
      "talks": [
        105,
        117,
        119,
        115
      ]
    },
    "s_10": {
      "id": "s_10",
      "title": "Coding Theory II",
      "talks": [
        26,
        61,
        78,
        95
      ]
    },
    "s_11": {
      "id": "s_11",
      "title": "Theory I",
      "talks": [
        49,
        108,
        116
      ]
    },
    "s_12": {
      "id": "s_12",
      "title": "Applications",
      "talks": [
        127,
        134,
        158
      ]
    },
    "s_13": {
      "id": "s_13",
      "title": "Distributed Learning/Computation",
      "talks": [
        24,
        131,
        161,
        184
      ]
    },
    "s_14": {
      "id": "s_14",
      "title": "Reinforcement Learning I",
      "talks": [
        28,
        48,
        181,
        73
      ]
    },
    "s_15": {
      "id": "s_15",
      "title": "Robustness I",
      "talks": [
        42,
        104,
        106
      ]
    },
    "s_16": {
      "id": "s_16",
      "title": "Theory III",
      "talks": [
        58,
        59,
        141
      ]
    },
    "s_17": {
      "id": "s_17",
      "title": "Statistics I",
      "talks": [
        13,
        39,
        89,
        156
      ]
    },
    "s_18": {
      "id": "s_18",
      "title": "Statistics II",
      "talks": [
        83,
        93,
        121
      ]
    },
    "s_19": {
      "id": "s_19",
      "title": "Information Theory I",
      "talks": [
        34,
        53,
        149
      ]
    },
    "s_20": {
      "id": "s_20",
      "title": "Information Theory II",
      "talks": [
        120,
        123,
        143
      ]
    },
    "s_21": {
      "id": "s_21",
      "title": "Graduation Day Talks I",
      "talks": [
        98,
        9,
        7,
        174
      ]
    },
    "s_22": {
      "id": "s_22",
      "title": "Graduation Day Talks III",
      "talks": [
        147,
        46,
        142,
        51
      ]
    },
    "s_25": {
      "id": "s_25",
      "title": "Graduation Day Talks II",
      "talks": [
        23,
        56,
        96,
        31
      ]
    },
    "s_26": {
      "id": "s_26",
      "title": "Graduation Day Talks IV",
      "talks": [
        102,
        118,
        29,
        122
      ]
    },
    "s_29": {
      "id": "s_29",
      "title": "Deep Learning",
      "talks": [
        2,
        6,
        90,
        124
      ]
    },
    "s_30": {
      "id": "s_30",
      "title": "Game Theory I",
      "talks": [
        72,
        101,
        113,
        155
      ]
    },
    "s_31": {
      "id": "s_31",
      "title": "Theory IV",
      "talks": [
        32,
        92
      ]
    },
    "s_32": {
      "id": "s_32",
      "title": "Online Learning",
      "talks": [
        87,
        126,
        85
      ]
    },
    "s_33": {
      "id": "s_33",
      "title": "Wireless Communication II",
      "talks": [
        22,
        84,
        94,
        33
      ]
    },
    "s_34": {
      "id": "s_34",
      "title": "Privacy",
      "talks": [
        45,
        151,
        163,
        80
      ]
    },
    "s_35": {
      "id": "s_35",
      "title": "Robustness II",
      "talks": [
        16,
        8
      ]
    },
    "s_36": {
      "id": "s_36",
      "title": "Robustness III",
      "talks": [
        111,
        128,
        41
      ]
    },
    "s_37": {
      "id": "s_37",
      "title": "Reinforcement Learning II",
      "talks": [
        3,
        79,
        177,
        178
      ]
    },
    "s_38": {
      "id": "s_38",
      "title": "Game Theory II",
      "talks": [
        0,
        60,
        103
      ]
    },
    "s_41": {
      "id": "s_41",
      "title": "Theory V",
      "talks": [
        20,
        27,
        140,
        170
      ]
    },
    "s_42": {
      "id": "s_42",
      "title": "Federated Learning",
      "talks": [
        136,
        74,
        166,
        179
      ]
    },
    "s_23": {
      "id": "s_23",
      "title": "Graduation Day Talks V",
      "talks": [
        185,
        137,
        50
      ]
    }
  },
  "days": {
    "1": {
      "id": "1",
      "tracks": [
        "tr_2",
        "tr_1"
      ]
    },
    "2": {
      "id": "2",
      "tracks": [
        "tr_4",
        "tr_3"
      ]
    },
    "3": {
      "id": "3",
      "tracks": [
        "tr_6",
        "tr_5"
      ]
    },
    "4": {
      "id": "4",
      "tracks": [
        "tr_8",
        "tr_7"
      ]
    },
    "5": {
      "id": "5",
      "tracks": [
        "tr_10",
        "tr_9"
      ]
    }
  },
  "schedule_list": [
    "1",
    "2",
    "3",
    "4",
    "5"
  ]
}