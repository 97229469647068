export const PARTICIPATION_STATUS_OPTIONS = [
  { value: 'ALMOST_CERTAINLY', displayText: 'Almost Surely' },
  { value: 'PROBABLY', displayText: 'Probably' },
  { value: 'PROBABLY_NOT', displayText: 'Probably Not' },
  { value: 'NEXT_TIME', displayText: 'Next Time' },
];

export const DAY_OPTIONS = [
  { value: 'Monday', displayText: 'Monday' },
  { value: 'Tuesday', displayText: 'Tuesday' },
  { value: 'Wednesday', displayText: 'Wednesday' },
  { value: 'Thursday', displayText: 'Thursday' },
  { value: 'Friday', displayText: 'Friday' },
];

export const RECEPTION_OPTIONS = [
  { value: 'ita24_sundayReception_notAttending', displayText: 'Not attending' },
  { value: 'ita24_sundayReception_selfOnly', displayText: 'You' },
  { value: 'ita24_sundayReception_selfPlus1', displayText: 'You + 1' },
  { value: 'ita24_sundayReception_selfPlus2', displayText: 'You + 2' },
];

export const LUNCH_OPTIONS = [
  { value: 'ita24_mondayLunch_notAttending', displayText: 'Not attending' },
  { value: 'ita24_mondayLunch_attending', displayText: 'Attending' },
];

export const BANQUET_SELF_OPTIONS = [
  { value: 'ita24_banquetSelf_notAttending', displayText: 'Not attending' },
  { value: 'ita24_banquetSelf_selfOnly', displayText: 'You' },
  { value: 'ita24_banquetSelf_selfPlus1', displayText: 'You + 1' },
  { value: 'ita24_banquetSelf_selfPlus2', displayText: 'You + 2' },
];

export const ITALT_OPTIONS = [
  { value: 'ita24_italt_attending', displayText: 'Attending' },
  { value: 'ita24_italt_notAttending', displayText: 'Not attending' },
];

export const SATURDAY_SELF_OPTIONS = [
  {
    value: 'ita24_saturdayWorkshop_notAttending',
    displayText: 'Not attending',
  },
  { value: 'ita24_saturdayWorkshop_attending', displayText: 'Attending' },
];

export const VALENTINES_EVENT_OPTIONS = [
  { value: 'ita24_valentinesEvent_notAttending', displayText: 'Not attending' },
  { value: 'ita24_valentinesEvent_selfOnly', displayText: 'You' },
  { value: 'ita24_valentinesEvent_selfPlus1', displayText: 'You + 1' },
  { value: 'ita24_valentinesEvent_selfPlus2', displayText: 'You + 2' },
];

export const FEETYPE_OPTIONS = [
  { value: 'FULL', displayText: 'Full' },
  { value: 'STUDENT', displayText: 'Student' },
  { value: 'AFFILIATE', displayText: 'Affiliate' },
  { value: 'EXEMPT', displayText: 'Exempt' },
  { value: 'WAIVED', displayText: 'Waived' },
];

export const REGISTRANT_TYPE_OPTIONS = [
  {
    value: 'INVITED',
    displayText: 'Invited',
  },
  {
    value: 'SELF_REGISTERED',
    displayText: 'Self-registered',
  },
];

export const BANQUET_GUEST_OPTIONS = [
  { value: 'F', displayText: '🐟' },
  { value: 'C', displayText: '🐔' },
  { value: 'V', displayText: '🥦' },
];
