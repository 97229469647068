import React from 'react';

export default class RegistrationInfo extends React.Component {
  render() {
    return (
      <div>
        <p>
          Registration includes daily breakfasts, morning and afternoon
          refreshments, light lunches on Wednesday and (TBD), and evening refreshments at the hospitality
          suite.
        </p>
      </div>
    );
  }
}
