import React from 'react';
import { Container } from 'reactstrap';
import CsvDownloader from 'react-csv-downloader';

import {
  api,
  fetchPaginatedRegistrations,
  fetchRegnOptions,
  generateRegnOptionConversions,
} from '../../../helpers';
import * as ita24Regn from '../../../models/ita24/Registration';
import RegistrationTable from './RegistrationTable';

const intDivRoundUp = (a, b) => {
  const quotient = Math.floor(a / b);
  const remainder = a % b;
  return quotient + (remainder === 0 ? 0 : 1);
};

function flatten(data) {
  var result = {};
  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
      if (typeof cur === 'string' || cur instanceof String) {
        cur = cur.replace(/,/g, '');
      }
      if (prop !== 'abstract') {
        result[prop] = cur;
      }
    } else if (Array.isArray(cur)) {
      for (var i = 0, l = cur.length; i < l; i++)
        recurse(cur[i], prop + '[' + i + ']');
      if (l == 0) result[prop] = [];
    } else {
      var isEmpty = true;
      for (var p in cur) {
        isEmpty = false;
        recurse(cur[p], p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, '');
  return result;
}

function flatten1(reg) {
  var temp = flatten(reg);
  console.log(temp);
  if (!temp instanceof Array) {
    console.log('Inside temp');
    var temp_array = [];
    temp_array.push(temp);
    console.log('temp:' + JSON.stringify(temp_array));
    return temp_array;
  }
  return temp;
}

export default class RegistrationStatsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingRegnOptions: true,
      loadingRegns: true,
      loadingInviters: true,
      numTotalPages: 0,
      registrations: [],
      regnOptionUrlToSlugMap: new Map(),
      inviterUrlToObjectMap: new Map(),
    };
  }

  componentDidMount() {
    fetchRegnOptions().then((regnOptions) => {
      const { urlToSlugMap } = generateRegnOptionConversions(regnOptions);
      this.setState({
        regnOptionUrlToSlugMap: urlToSlugMap,
        loadingRegnOptions: false,
      });
    });

    api('api/v0/inviters/').then((inviters) => {
      const urlToObjectMap = new Map(
        inviters.map((inviter) => [inviter.url, inviter])
      );
      this.setState({
        inviterUrlToObjectMap: urlToObjectMap,
        loadingInviters: false,
      });
    });
  }

  fetchRegnData = (state, instance) => {
    const { page, pageSize } = state;
    new Promise((resolve) => this.setState({ loadingRegns: true }, resolve))
      // need to add 1 to page, since API is 1-indexed and react-table is
      // 0-indexed
      .then(() => fetchPaginatedRegistrations(page + 1, pageSize))
      .then(({ results, count: numTotalResults }) => {
        const registrations = results.map((result) =>
          ita24Regn.fromApiFormat(result, this.state.regnOptionUrlToSlugMap)
        );
        var csvoutput = [];
        for (var registration of registrations) {
          console.log(registration);
          csvoutput.push(flatten1(registration));
        }
        console.log('CSVoutput:' + csvoutput);
        const numTotalPages = intDivRoundUp(numTotalResults, pageSize);
        this.setState({
          csvoutput,
          registrations,
          numTotalPages,
          loadingRegns: false,
        });
      });
  };

  getInviterByUrl = (url) => this.state.inviterUrlToObjectMap.get(url);

  render = () => {
    const loadingAuxiliaryData =
      this.state.loadingRegnOptions || this.state.loadingInviters;
    const headers = [
      { displayName: 'Last name', id: 'last_name' },
      { displayName: 'First name', id: 'first_name' },
      { displayName: 'Title', id: 'honorific' },
      { displayName: 'Student?', id: 'is_student' },
      { displayName: 'Affiliation', id: 'affiliation_title' },
      { displayName: 'Email', id: 'email' },
      { displayName: 'Inviter', id: 'inviter' },
      { displayName: 'Invited?', id: 'registrantType' },
      { displayName: 'Status', id: 'participationStatus' },
      { displayName: 'Regn. #', id: 'registrantNumber' },
      { displayName: 'Monday', id: 'attendingDates[0]' },
      { displayName: 'Tuesday', id: 'attendingDates[1]' },
      { displayName: 'Wednesday', id: 'attendingDates[2]' },
      { displayName: 'Thursday', id: 'attendingDates[3]' },
      { displayName: 'Friday', id: 'attendingDates[4]' },
      { displayName: 'Saturday', id: 'attendingDates[5]' },
      { displayName: 'Sunday reception', id: 'sundayReception' },
      { displayName: 'Monday lunch', id: 'mondayLunch' },
      { displayName: 'Banquet', id: 'banquetSelf' },
      { displayName: 'PTY', id: 'presenting' },
      { displayName: 'PBD', id: 'presenting_default' },
      { displayName: 'Title', id: 'title' },
      { displayName: 'Fee type override', id: 'feeTypeOverride' },
      { displayName: 'Paid?', id: 'hasApprovedPayment' },
      { displayName: 'Paid amount', id: 'approvedPaymentsTotalAmount' },
      { displayName: 'Scheduling comments', id: 'scheduling_comment' },
      { displayName: 'Topic comments', id: 'topic_comment' },
      { displayName: 'Abstract', id: 'abstract' },
    ];
    return (
      <div>
        {this.props.navbar}
        <Container fluid={true}>
          {loadingAuxiliaryData ? (
            <p>Loading table...</p>
          ) : (
            <div>
              {this.state.loadingRegns ? (
                <p style={{ float: 'right', padding: 5 + 'px' }}>
                  Loading data...
                </p>
              ) : (
                <div style={{ float: 'right', padding: 5 + 'px' }}>
                  <CsvDownloader
                    filename="Registration statistics"
                    separator=","
                    wrapColumnChar=""
                    columns={headers}
                    datas={this.state.csvoutput}
                    text="Download Data"
                  />
                </div>
              )}
              <RegistrationTable
                registrations={this.state.registrations}
                numTotalPages={this.state.numTotalPages}
                loadingRegns={this.state.loadingRegns}
                onFetchData={this.fetchRegnData}
                getInviterByUrl={this.getInviterByUrl}
              />
            </div>
          )}
        </Container>
      </div>
    );
  };
}
